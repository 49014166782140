﻿html {
  scroll-behavior: smooth;
}
*{
    font-family: $preferred_font ;
}

::ng-deep .cdk-overlay-container .cdk-overlay-pane.mat-select-panel {
    min-width: 100% !important;
}

.mat-select-panel {
min-width: 100% !important;
}


.wp_flex{
    &--container{
      @include flexbox();

       &.space-between{
         justify-content: space-between !important;
       }
    }
    &__item{
      @include align-self(auto);
      @include  flex(1 1 auto);

      &--inherit{
        flex-grow: inherit;
      }

      &--vcenter{
        display: flex;
        align-items: center;
      }
      &--vbottom{
        display: flex;
        align-items: flex-end;
      }
      &.p5{
        width: 5%;
      }
      &.p10{
        width: 10%;
      }
      &.p15{
        width: 15%;
      }
      &.p20{
        width: 20%;
      }
      &.p25{
        width: 25%;
      }
      &.p30{
        width: 30%;
      }
      &.p33{
        width: 33%;
      }
      &.p40{
        width: 40%;
      }
      &.p50{
        width: 50%;
      }
      &.p70{
        width: 70%;
      }
      &.p75{
        width: 75%;
      }
      &.p100{
        width: 100%;
      }
    }
}
.wp{
    &--flex-box{
      @include flexbox();
      @include flex-direction(row);
      @include flex-wrap(nowrap);
      @include align-content(stretch);
      @include align-items(flex-start);}
    // Add one of the following classes to any html element to achieve a desired depth of the shadow


    &--depth__1{
      @include box_shadow(1);
    }

    &--depth__2{
      @include box_shadow(2);
    }

    &--depth__3{
      @include box_shadow(3);
    }

    &--depth__4{
      @include box_shadow(4);
    }
    &--depth__5{
      @include box_shadow(5);
    }

  }
  .container-fluid{
    padding: 0px;
    // @include respond-to("x-large") {
    //   wpx-width: 1900px !important;
    // }
  }
    .container{
      // @include respond-to("large") {
      //     min-width: 1400px;
      // }
  }
.__link{
  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}

//Sidenav global styles
.mat-sidenav-container{
    //height: 100vh;
    position:absolute !important;
    z-index:1000 !important;
    .mat-drawer-inner-container{
        overflow:unset;
        overflow-x:visible;
    }
    .mat-sidenav{
        height: 100vh;
        width: 530px;
        padding:15px;
        background-color:$wp-background-grey;
        overflow: visible;
        position:fixed;
        overflow-y:auto;
    }
    .arrow-button{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: $wp-color-primary;
            color:$wp-color-white;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            right: 25px;
        }
    .btn-sidepanel-open{
        position: fixed;
        top: 15%;
        bottom: 50%;
        right: 0px;
        width: 70px;
        height: 550px;
        min-width: unset !important;
        border-radius: 10px 0px 0px 10px !important;
        padding:10px;
        &:hover{
            background-color:$wp-background-grey !important;
            border: 1px solid $wp-color-primary;
            color:$wp-color-primary !important;
            cursor:pointer;
        }
        .mat-button-wrapper{
            display: flex;
            height: 100%;
            position:relative;
            align-items: center;
        }
    }
    .sidepanel-btn-summary{
        transform: rotate(-90deg);
        position: absolute;
        right: -236px;
        top: 50%;
        width: 520px;
        height: 40px;
        .mat-btn-icon{
            margin: 0 10px;
            border: 1px solid $wp-color-primary;
            border-radius:4px;
        }
        .mat-button-wrapper{
            width: 25px !important;
            height: 25px;
        }
        .team-label{
            max-width:120px;
            color: $wp-color-primary !important;
        }
    }
    .btn-sidepanel-close{
        /*position: absolute;
        left: -30px;
        top: 50%;
        bottom: 50%;
        width: 30px;
        height: 100px;
        min-width: unset;
        border-radius: 10px 0px 0px 10px !important;
        padding: 0px;*/
        width: 30px;
        min-width:30px !important;
        height: 30px;
        border-radius: 50% !important;
        background-color: $wp-color-primary !important;
        color: $wp-color-white !important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: 515px;
        //left: -30px;
        top: 50%;
        bottom: 50%;
        padding: 0px;
        min-width:unset;
        .mat-button-wrapper{
            i{
                color:$wp-color-white;
            }
        }
    }
    .sidepanel-header{
        background-color:$wp-color-white;
        margin-bottom:10px;
        //padding:10px;
        //box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
        .mat-btn-icon{
            margin: 0 10px;
            border: 1px solid $wp-color-primary;
            border-radius:4px;
            display:flex;
            cursor:pointer;
        }
        .select-team-dropdown{
            margin-top: 15px;
            margin-bottom: 5px;
            .mat-form-field{
                width:100%;
                .mat-select-value-text{
                    color: $wp-color-primary;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
        .sidepanel-team-selection-container{
            background-color:$wp-color-white;
            padding:10px;
            box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
            border-radius:5px;
        }
    }
    .sidepanel-expanded-row{
        background-color:$wp-color-white;
        padding:10px 0px;
        margin:0px;
        margin-bottom:5px;
    }
}

//Expansion Panel global styles
.mat-expansion-panel{
    position: relative;
    z-index: 995;
    box-sizing: border-box !important;
    .mat-expansion-panel-header{
        @include box_shadow (1);
        padding:0 20px;
        min-height:55px !important;
        height:auto !important;
        position: relative;
        z-index: 996;
    }
    .mat-expansion-panel-body{
        background-color:$wp-background-grey;
        padding:15px;
    }
}

//Multi select global styles
.mat-primary{
     .mat-pseudo-checkbox-checked{
         background-color:$wp-color-primary;
         &::after{
            color:#fff;
            top: 4.4px;
            left: 2px;
         }
     }
}

//Chip
.wp-chip-primary{
    background-color:$wp-icon-primary;
    color:#fff;
    padding: 0px 10px;
    border-radius: 12px;
}
//Calendar global styles
.mat-calendar-table{
    .mat-calendar-table-header{
        th{
            border:0px;
        }
        .mat-calendar-table-header-divider{
            padding:0px;
            &:after{
                height:0px;
            }
        }
    }
}
//Checkbox global styles
.mat-checkbox{
    &.mat-checkbox-checked{
        .mat-checkbox-background{
            background-color: $wp-color-primary;
        }
    }
    .mat-checkbox-layout{
        margin-bottom:0px;
    }
    .mat-checkbox-label{
        font-size:16px;
        font-weight:400;
    }
    .mat-checkbox-inner-container{
        vertical-align:unset;
        top:2px;
    }
    .mat-checkbox-ripple{
        .mat-ripple-element{
            background-color: $wp-color-primary !important;
        }
    }
}




//Tab global styles
.mat-tab-group{
    margin:0px;
    .mat-tab-header{
        border-bottom: 3px solid $wp-background-dark-grey;
    }
    .mat-tab-body{
        background-color: $wp-color-white;
        padding: 15px 0px;
    }
    .mat-tab-body-content{
        box-shadow:none;
    }
    .mat-tab-label{
        font-size: 16px;
        letter-spacing: normal;
        line-height: 1.6875rem;
        word-spacing: 0;
    }
    // .mat-tab-label-active{
    //     background-color:$wp-color-primary !important;
    //     color:$wp-color-white !important;
    // }
    .mat-ink-bar{
        background-color: $wp-color-primary !important;
    }
}
//Snackbar
.mat-snack-bar-container{
    padding:0px !important;
    min-height:unset !important;
    color: $wp-color-white;
}

//Menu global styles
.mat-menu-panel{
    max-width:unset !important;
    padding:15px;
    border: 2px solid #bebebe82 !important;
    .mat-menu-content{
        padding:0px;
    }
}

//Popup global styles

//Icons global styles
.material-icons{
    color:$wp-icon-primary;
    font-size:20px;
}
.icon-user{
    font-family: icomoon !important;
}

//Other Classes


//Layout styles for RB
opt-regular-booking ,.opt-regular-booking{
    min-height: calc(100vh - 875px);
    font-family: $preferred_font !important;
    background-color: $wp-background-grey;
//Container global styles
.container{
    max-width: 87vw;
}
.wp-overlay-container{
    .cdk-overlay-backdrop{
        position:fixed;
        z-index:10000;
    }
    .cdk-global-overlay-wrapper{
        position:fixed;
        z-index:10000;
    }
}
//Radio button global styles
.mat-radio-button{
    &.mat-radio-checked{
        .mat-radio-outer-circle{
            border-color: $wp-color-primary !important;
        }
        .mat-radio-inner-circle{
            background-color: $wp-color-primary !important;
        }
    }
    .mat-radio-ripple{
        .mat-ripple-element{
            background-color: $wp-color-primary !important;
        }
    }
    .mat-radio-label-content{
        font-weight:400;
    }
    .mat-radio-label{
        margin-bottom:0px;
    }
    .mat-radio-container{
        top:0px !important;
        height:20px;
    }
}
    .mat-form-field{
        margin-bottom:3px;
        .mat-form-field-wrapper{
            padding-bottom:2px;
        }
        .mat-form-field-label-wrapper{
            min-height: 50px;
            top: -21px;
        }
        .mat-form-field-infix{
            padding:0px;
            border-top:none;
            font-size: 16px;
            letter-spacing: normal;
            word-spacing: 0;
        }
        .mat-form-field-underline{
            bottom:0px;
        }
        .mat-paginator-page-size{
            align-items:center !important;
        }
        .mat-paginator-page-size-select{
            margin:unset;
            margin-bottom:5px;
        }
        &.mat-form-field-should-float{
            .mat-form-field-label{
                top:25px;
                font-weight:500;
                color:$wp-txt-secondary;
            }
        }
    }
    .mat-button{
        font-family: $preferred_font !important;
        word-spacing: unset;
        letter-spacing: unset;
        transition:0.3s !important;
        min-width: 100px;
    }
    .mat-btn{
      &-primary { /*default*/
        @extend %btn;
        @include box_shadow (1);
        font-size:16px !important;
        color: $wp-color-white !important;
        text-align: center !important;
        background-color: $wp-color-primary !important;
        &:hover{
          @include box_shadow (2);
        }

        &--small {
          @extend %btn-small;
          @include box_shadow (1);
          font-family: $preferred_font;
            color: $wp-color-white !important;
            background-color: $wp-color-primary !important;
            text-align: center !important;
            font-size: 14px !important;
          &:hover{
            @include box_shadow (2);
          }
        }
      }
      &-secondary { /*default*/
        @extend %btn;
        @include box_shadow (1);
        font-family: $preferred_font;
        font-size:16px !important;
        text-align: center !important;
        border:1px solid $wp-color-primary;
        color: $wp-color-primary !important;
        background-color:$wp-color-white;
        &:hover{
          background-color:$wp-color-primary;
          color: $wp-color-white !important;
        }

        &--small {
          @extend %btn-small;
          @include box_shadow (1);
            border:1px solid $wp-color-primary;
            color: $wp-color-primary !important;
            text-align: center !important;
            font-size: 14px !important;
            background-color:$wp-color-white;
          &:hover{
            background-color: $wp-color-primary !important;
            color: $wp-color-white !important;
          }
        }
      }
      &-tertiary { /*default*/
        @extend %btn;
        @include box_shadow (1);
        font-family: $preferred_font;
        font-size:16px !important;
        text-align: center !important;
        border:1px solid $wp-danger-red;
        color: $wp-danger-red !important;
        background-color:$wp-color-white;
        &:hover{
          background-color:$wp-danger-red;
          color: $wp-color-white !important;
        }

        &--small {
          @extend %btn-small;
          @include box_shadow (1);
            border:1px solid $wp-danger-red;
            color: $wp-danger-red !important;
            text-align: center !important;
            font-size: 14px !important;
            background-color:$wp-color-white;
          &:hover{
            background-color: $wp-danger-red !important;
            color: $wp-color-white !important;
          }
        }
      }
      &-icon{
        min-width: unset;
        padding: 0px;
        color:$wp-icon-primary;
        transition:0.3s;
        @include box_shadow (1);
        .mat-button-wrapper{
            width: 40px ;
            height: 40px;
            min-width: unset;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &--secondary{
            cursor:pointer;
            transition:0.3s;
            &:hover{
                color:$wp-color-primary;
            }
            &.icon-delete{
                &:hover{
                    color:$wp-danger-red;
                }
            }
        }
        &.btn-delete{
            transition:0.3s;
            &:hover{
                    i{
                        color:$wp-danger-red;
                    }
                }
        }
      }


}
    .mat-paginator{
        width:100%;
    }
    .mat-stepper-horizontal{
        .mat-step-header{
            .mat-step-icon-selected{
                background-color:$wp-color-primary;
            }
            .mat-step-label{
                padding:5px 0px 0px 0px;
                &.mat-step-label-selected{
                    color:$wp-color-primary;
                    font-weight:500;
                }
            }
        }
    }
    .mat-step-icon-state-done{
        background-color:$wp-secondary-green !important;
        .material-icons{
            color:$wp-color-white;
        }
    }
    input {
      caret-color: $wp-txt-primary !important;
    }
}
.wp-loading-spinner--full{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    z-index: 9999;
    position: relative;
    .loading-spinner--body{
        background-color: $wp-color-white;
        padding: 35px;
        width: 500px;
    }
    .mat-spinner circle{
        stroke:$wp-color-primary !important;
        stroke-width: 5% !important;
    }
}
.wp-show{
    display:block !important;
}
.wp-hide{
    display:none !important;
}
.wp-form-field--error{
    .mat-form-field-underline{
        height:2px !important;
        background-color:$wp-txt-danger !important;
    }
}
//Client specific styles - NBC
.nbc{
    .wp-rb-main-layout{
        .message--bubble-notification{
            width:320px;
        }
    }
    .btn-sidepanel-open{
        top:7%;
    }
    @media only screen and (min-width: 1400px) {
        .btn-sidepanel-open{
            top:18%;
        }
    }
    .sidepanel-btn-summary{
        top: 47%;
    }
    .wp-txt-body2, .wp-txt-body2--small{
        font-weight: 700 !important;
    }
    .wp-heading-4, .wp-heading-3, .wp-heading-2{
        font-weight: 700 !important;
    }
    .mat-primary{
        .mat-pseudo-checkbox-checked{
            &:after{
                top: 3px;
                left: 1px;
            }
        }
    }
    .body-wrap{
        padding-top:0px !important;
    }
    .header{
        position:relative !important;
    }
    .footer__back-to-top{
        cursor:pointer;
    }
    .header-hamburger-icon{
        color: #000000 !important;
        background: #c9da43;
        padding: 5px 10px 5px 10px !important;
        margin-right: 15px;
        border-radius: 3px;
        display: flex;
        &:hover{
            color: #c9da43 !important;
            background: #000000;
        }
    }
}
.cog{
    .wp-txt-body2, .wp-txt-body2--small{
        font-weight: 600 !important;
    }
    .wp-heading-4, .wp-heading-3, .wp-heading-2{
        font-weight: 500 !important;
    }
    .home-inner-container{
        padding:0px 25px !important;
    }
}
//Common for CB & RB
.header__user{
    @media screen and (max-width: 415px) {
        margin-bottom: 85px;
        z-index: 999999;
    }
    .user{
        height:65px;
        .header__alter-menu{
            align-items: center;
            display: flex;
        }
        .sub_left{
            @media screen and (max-width: 768px) {
                flex: 0 0 100%;
                max-width: unset;
            }
            @media screen and (max-width: 375px) {
                flex-direction: column;
                align-items: flex-start;
                padding-top: 10px;

                .wp-mr-20 {
                    padding-bottom: 10px;
                }
            }
            width:60%;
            .header-nav-venue, .header-nav-rb{
                height: 25px;
                min-width:180px;
                padding-right: 10px;
                display: flex;
                align-items: center;
                transition:0.3s;
                text-decoration: unset !important;
                &:hover{
                    background-color: #e0e0e0;
                    border-radius: 12px;
                }
            }
            .header-nav-venue{
                &:before{
                    content: 'account_balance';
                    font-family: "Material Icons";
                    transition:0.3s;
                    margin-right: 5px;
                    background-color: #7E57C2;
                    color: #fff;
                    border-radius: 50%;
                    padding: 5px;
                    height: 25px;
                    width: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &:hover{
                    &:before{
                        background-color: #fff;
                        color: #7E57C2;
                    }
                }
            }
            .header-nav-rb{
                &:before{
                    content: 'assignment';
                    font-family: "Material Icons";
                    transition:0.3s;
                    font-size:15px;
                    margin-right: 5px;
                    background-color: #7E57C2;
                    color: #fff;
                    border-radius: 50%;
                    padding: 5px;
                    height: 25px;
                    width: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &:hover{
                    &:before{
                        background-color: #fff;
                        color: #7E57C2;
                    }
                }
            }
        }
        .sub{
            width: 40% !important;
            justify-content: flex-end;
            @media only screen and (max-width: 768px){
                width: 100% !important;
            }
            @media print{
                width: auto !important;
                display: flex !important;
                justify-content: flex-end !important;
            }
            .user__left{
                flex-grow: inherit !important;
            }
        }
    }
}

.public-web .checkout-page .checkout-stepper .delivery-method-details{
    .mat-checkbox .mat-checkbox-inner-container{
      top: 5px !important;
    }
  }

.user-menue-wrapper.ng-star-inserted {
    display: flex !important;
    align-items: center !important;
}