﻿.wp-document-upload{
    background-color:$wp-background-grey;
    padding: 15px;
    .mat-button{
        background-color:$wp-color-white;
    }
    .document-upload-container{
        background-color:$wp-color-white;
        padding:15px;
        box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
        margin-top: 20px;
        margin-bottom: 30px;
        .mat-form-field{
            width:300px;
        }
    }
    .upload-panel{
        border: 1px dotted $wp-color-secondary;
        padding: 15px;
        .drop-zone{
            border: 1px dotted $wp-color-primary !important;

            div:nth-child(3) {
                display: none !important;
            }
        }
    }
    .upload-icon{
        background: $wp-icon-primary;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right:10px;
        i{
            color:$wp-color-white;
        }
    }
    .uploaded-documents-wrapper{
        .uploaded-documents-row{
            display:flex;
            align-items: center;
            justify-content:space-between;
            margin-bottom:10px;
            .left-col{
                padding:15px;
                box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
                width:calc(100% - 60px);
                border-radius:10px;
                background-color:#ffffff; 
                .mat-form-field{
                    width: 70%;
                }
            }
            .right-col{
                //margin-left: 15px;
            }
        }
    }
}