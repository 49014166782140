﻿.wp-add-teams{
    background-color:#ffffff;
    .mat-form-field{
        width:100%;
    }
    .add-teams-header{
        padding: 15px 75px 15px 15px;
    }
    .add-teams-row{
        display:flex;
        align-items: center;
        justify-content:space-between;
        margin-bottom:10px;
        .left-col{
            padding:15px;
            box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
            width:calc(100% - 60px);
            border-radius:10px;
            background-color:#ffffff; 
        }
        .right-col{
            //margin-left: 15px;
        }
    }
    .add-more-button{
        margin-top:20px;
        width: calc(100% - 60px);
        height: 50px;
        background-color:#ffffff;
        @include box_shadow (1);
        i{
            margin-right:10px;
            color:$wp-color-primary;
        }
    }
}