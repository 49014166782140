﻿.rb-web-none{
    display: none !important;
}
.wp-rb-main-layout{
    @include respond-to('x-small'){
        display:none;
    }
    @include respond-to('medium'){
        display:block;
    }
    background-color:$wp-background-grey;
    
    .wp-main-header{
        padding:15px;
        background-color:#fff;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
        margin-bottom:30px;
        i{
            color:$wp-color-2;
        }
        .profile-avatar{
            height: 35px;
            width: 35px;
            margin-right: 10px;
            border-radius:50px;
            overflow:hidden;
            margin-right:10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height:35px;
            }
        }
    }
    .step-header{
        margin-bottom:20px;
        .export-icon{
            color:$wp-color-2;
        }
        .mat-stepper-label-position-bottom{
            .mat-horizontal-stepper-header{
                .mat-step-label{
                    padding:5px 0px 0px 0px;
                }
            } 
        }
    }
    .wp-stepper{
        .mat-horizontal-content-container{
            overflow:visible;
        }
    }
    .wp-add-teams{
        margin-top:30px;
    }
    .stepper-nav-button-row{
        margin-top:50px;
    }
    .calendar-toggle, .date-selection-toggle{
        //margin-right: 30px;
            @include respond-to('x-small'){
                margin-top:15px;
            }
            @include respond-to('medium'){
                margin-top:0px;
            }
        border:unset;
        .mat-button-toggle{
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            border: 1px solid $wp-color-primary;
            color: $wp-color-primary!important;
            text-align: center !important;
            font-size: 14px !important;
            transition: 0.3s !important;
            height: 30px;
            padding: 0 10px;
            width: auto;
            &:nth-child(1){
                border-radius: 10px 0px 0 10px !important;
            }
            &:nth-last-child(1){
                border-radius: 0 10px 10px 0px !important;
            }
            .mat-button-toggle-label-content{
                line-height:28px;
            }
        }
        .mat-button-toggle-checked{
            background-color:$wp-color-primary;
            color:$wp-color-white !important;
        }
    }
   
    .btn-location-resource-search{
        height:40px !important;
    }
    .message--bubble-notification{
        position: fixed;
        right: 60px;
        z-index: 9999;
        top: 130px;
        min-width: 320px;
    }
    .booking-application-name-wrapper{
        padding:20px 10px; 
        background-color:$wp-color-white;
        box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
        border-radius: 5px;
        width: 90%;
        //margin-right: 20px;
        .mat-form-field{
            width:100%;
        }
    }
    .submit-panel-container{
        background-color:$wp-background-grey;
        margin-top:20px;
        padding:15px;
    }
    .terms-checkbox{
        .mat-checkbox-inner-container{
            margin: 0;
            margin-right: 10px;
        }
    }
    .submit-documents-panel, .submit-additional-notes-panel{
        padding:15px; 
        background-color:$wp-color-white;
        box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
        border-radius: 5px;
        height:100%;
        .mat-form-field{
            width:100%;
        }
    }
    .wp-booking-details-modal{
        .wp-modal-body{
            max-height:450px;
        }
    }
}
.wp-rb-home{
    @include respond-to('x-small'){
        display:none;
    }
    @include respond-to('medium'){
        display:block;
    }
    padding-bottom:30px;
    .wp-main-header{
        padding:15px;
        background-color:#fff;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
        margin-bottom:30px;
        i{
            color:$wp-color-2;
        }
        .profile-avatar{
            height: 35px;
            width: 35px;
            margin-right: 10px;
            border-radius:50px;
            overflow:hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height:35px;
            }
        }
    }
    .home-inner-container{
        background-color: $wp-color-white;
        padding: 0px;
        border-radius: 5px;
        .booking-type-container{
            @include respond-to('x-small'){
                flex-wrap:wrap;
            }
            @include respond-to('large'){
                flex-wrap:wrap;
            }
            >.wp_flex__item{
                
                padding-right:15px;
                @include respond-to('x-small'){
                    max-width:50%;
                }
                @include respond-to('large'){
                    max-width:25%;
                }
                &:nth-last-child(1){
                    //padding-right:0px;
                }
                &:nth-child(1){
                    .btn-booking-type{
                        &:hover{
                            border-bottom:3px solid $wp-color-primary;
                        }
                    }
                }
                &:nth-child(2){
                    .btn-booking-type{
                        &:hover{
                            border-bottom:3px solid $wp-color-secondary;
                        }
                    }
                }
                &:nth-child(3){
                    .btn-booking-type{
                        &:hover{
                            border-bottom:3px solid $wp-color-tertiary;
                        }
                    }
                }
            }
        }
        .btn-booking-type{
            box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
            padding:20px;
            transition:0.1s !important;
            height:200px;
            width:100%;
            @include respond-to('x-small'){
                margin-bottom:15px;
            }
            @include respond-to('large'){
                margin-bottom:10px;
            }
            i{
                font-size: 35px;
                margin-bottom: 10px;
            }
            img{
                margin-bottom:15px;
            }
            .btn-description{
                max-width: 350px;
                min-width: 210px;
                white-space: normal;
                font-weight: 400;
                line-height:18px;
            }
            /*&.btn-regular-booking{
                i{
                    color:$wp-color-primary;
                }
                &:hover{
                    border-bottom:3px solid $wp-color-primary;
                }
            }
            &.btn-seasonal-booking{
                i{
                    color:$wp-color-secondary;
                }
                &:hover{
                    border-bottom:3px solid $wp-color-secondary;
                }
            }
            &.btn-personal-training{
                i{
                    color:$wp-color-tertiary;
                }
                &:hover{
                    border-bottom:3px solid $wp-color-tertiary;
                }
            }*/
        }
    }
}

.device-support-message{
    height: 50vh;
    align-items: center;
    padding: 25px;
    @include respond-to('x-small'){
        display:flex;
    }
    @include respond-to('medium'){
        display:none;
    }
}

.wp-rb-home {
    .wp-main-header {
        .wp-txt--link {
            color: #000000 !important;
            span {
                min-width: 200px;
                display: flex;
                align-items: center;

                &:before {
                    content: 'account_balance';
                    font-family: "Material Icons";
                    background-color: #c9da43 ;
                    color: #000 !important;
                    transition: 0.3s;
                    margin-right: 5px;
                    border-radius: 50%;
                    height: 25px;
                    width: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                &:hover {
                    background-color: #e0e0e0;
                    border-radius: 12px;
                    transition: 0.3s;
                    text-decoration: none;
                    cursor: pointer;
                    &:before{
                        background-color: #000 ;
                        color: #c9da43 ;
                    }
                }
            }
        }
    }
}
