﻿@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~animate.css/animate.min.css";
@import "abstracts/_mixins.scss";
@import "abstracts/_flexbox.scss";
/*@import "abstracts/_variables.scss";*/
@import "abstracts/_icon-font.scss";
@import "abstracts/_buttons.scss";

@import "base/_misc.scss";
@import "base/_typography.scss";
@import "base/_utilities.scss";

@import "components/_recurring-booking-popup.scss";
@import "components/_modals.scss";
@import "components/_popups.scss";
@import "components/_carousel.scss";
@import "components/_calendar.scss";
@import "components/_document-upload.scss";
@import "components/_messages.scss";
@import "components/_diary.scss";

@import "./pages/_location-resource.scss";
@import "./pages/_seasons.scss";
@import "./pages/_questionnaire.scss";
@import "./pages/_layout.scss";
@import "./pages/_extra-allocation.scss";
@import "./pages/_deletion-allocation.scss";