﻿.wp-questionnaire{
    .filter-panel{
        background-color:$wp-color-white;
        border-radius:10px;
        padding:15px;
        margin-bottom:30px;
        box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
        .mat-form-field{
            width:100%;
            .mat-form-field-infix{
                line-height: 30px;
            }
        }
    }
    .questionnaire-header{
        margin-bottom:20px;
        .mat-slide-toggle{
            height:16px;
            &.mat-checked{
                .mat-slide-toggle-bar{
                    background-color:#2574a96b;
                }
                .mat-slide-toggle-thumb{
                    background-color:$wp-color-primary;
                }
                .mat-ripple-element{
                    background-color:$wp-color-primary;
                }
            }
        }
    }
    .questionnaire-questions-wrapper{
        background-color: $wp-background-grey;
        padding: 15px;
        .question-title{
            margin:10px 0px;
        }
        .mat-radio-button{
            margin-right:15px;
        }
        .mat-form-field{
            width:100%;
        }
        .mat-expansion-panel{
            &.mat-expanded{
                margin-bottom: 10px !important;
            }
        }
    }
}