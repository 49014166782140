﻿@import "../abstracts/_mixins.scss";



body{
  color:$base-text-color ;
  font-family: $preferred_font !important;
  font-size:16px;
}

.wp-txt--primary {
  color: $wp-txt-primary !important;
}
.wp-txt--secondary {
  color: $wp-txt-secondary !important;
}
.wp-txt--tertiary {
  color: $wp-txt-tertiary !important;
}
.wp-txt--quaternary {
  color: $wp-txt-quaternary !important;
}
.wp-txt--danger {
  color: $wp-txt-danger !important;
}
.wp-txt--white {
  color: #ffffff !important;
}
.wp-primary-icon{
    color:$wp-icon-primary;
    padding:0 5px;
}

//Sizes
.wp-section-title{
    margin-bottom:20px;
}
.wp-txt-title{
    font-size:18px !important;
    color:$wp-txt-primary;
}
.wp-txt-subtitle{
    font-size:16px !important;
    color:$wp-txt-secondary;
    //margin-bottom:5px;
}


.wp-txt--underline{
  text-decoration: underline;
}
.wp-txt--line-through{
  text-decoration: line-through;
}
.wp-txt--line-overline{
  text-decoration: overline;
  font-size: 12px;
  line-height: 16px;
}


.wp-txt{
  
  &-align--right {
   text-align: right !important;
  }
  &-align--left {
    text-align: left !important;
   }
  &-align--center {
    text-align: center !important;
   }
}


h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h1.wp__heading-1 small,   h2.wp__heading-2 small, h3.wp__heading-3 small, h4.wp__heading-4 small,   h5.wp__heading-5 small, h6.wp__heading-6 small {
   @include typo-display-3($colorContrast: false);
   font-size: 0.6em;
}
%tc-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  h1.wp-heading-1 {
  @include typo-display-3;

    margin-top: 0;
    margin-bottom: 0;
    @extend %tc-ellipsis;
  }

  h2.wp-heading-2 {
    @include typo-display-2;
    margin-top: 0;
    margin-bottom: 0;
  }

  h3.wp-heading-3 {
    @include typo-display-1;

    margin-top: 0;
    margin-bottom: 0;
  }
  h4.wp-heading-4 {
    @include typo-display-1;
    font-size:18px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .wp-subtitle-1 {
    @include typo-headline;
    font-size: 16px ;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;


    &-small{
      @include typo-headline;
      font-size: 12px ;
      line-height: 12px;

    }
    &-large{
      @include typo-headline;
      font-size: 16px ;
      line-height: 16px;
    }
  }

  .wp-subtitle-2 {
    @include typo-title;
    font-size: 16px;
    line-height: 16px;


    &-small{
      @include typo-title;
      font-size: 14px ;
      line-height: 14px;
    }

    &-large{
      @include typo-title;
      font-size: 18px ;
      line-height: 18px;
    }
  }


.wp-txt--caption{
  @include typo-caption;
  font-style: normal;
  color: #909cac;
}
.wp-txt--link{
  color: $wp-color-primary !important;
  text-decoration: none;
  cursor:pointer;
  &:hover{
      text-decoration: underline !important;
  }
}


.wp-txt-body2{
  @include typo-body-2;
  @extend %tc-ellipsis;
  line-height: 1.5;
  white-space: normal;
  &--xsmall{
    font-size: 12px !important;
    @include typo-body-2;
    @extend %tc-ellipsis;
    
  }
  &--small{
    @include typo-body-2;
    @extend %tc-ellipsis;
    font-size: 14px !important;
  }
  &--large{
    @include typo-body-2;
    @extend %tc-ellipsis;
    font-size: 18px;
  }

}

.wp-txt-body1{
  @include typo-body-1;
  @extend %tc-ellipsis;
  white-space: normal;
  &--xsmall{
    font-size: 12px;
    white-space: normal;
    @extend %tc-ellipsis;
  }
  &--small{
    @include typo-body-1;
    white-space: normal;
    @extend %tc-ellipsis;
    font-size: 14px !important;
  }
  &--large{
    font-size: 18px;
    @extend %tc-ellipsis;
  }
}