﻿.mat-snack-bar-container{
    min-height:unset;
}
.wp-timeslot-message{
    .success-message{
        background-color:$wp-success-green;
        padding:10px;
        border-radius:5px;
    }
}
.message--success{
    background-color:$wp-color-white;
    color:$wp-success-green !important;
    padding:15px;
    box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
    margin:15px 0px;
    border-radius: 10px;
    .message-icon{
        width: 45px;
        height: 45px;
        border: 3px solid $wp-success-green;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right:15px;
        i{
            font-size:25px;
            color:$wp-success-green;
        }
    }
}
.message--error{
    background-color:$wp-color-white;
    color:$wp-danger-red !important;
    padding:15px;
    box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
    margin:15px 0px;
    border-radius: 10px;
    .message-icon{
        width: 45px;
        height: 45px;
        border: 3px solid $wp-danger-red;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right:15px;
        span{
            font-size:25px;
            color:$wp-danger-red;
            font-weight:600;
        }
    }
}
.message--info{
    background-color:$wp-color-white;
    color:$wp-color-2 !important;
    padding:15px;
    box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
    margin:15px 0px;
    border-radius: 10px;
    .message-icon{
        width: 45px;
        height: 45px;
        border: 3px solid $wp-color-2;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right:15px;
        span{
            font-size:25px;
            color:$wp-color-2;
            font-weight:600;
        }
    }
    .message-help-image{
        margin-right:-10px;
    }
}
.message--fill-mandatory{
    background-color:$wp-color-white;
    color:$wp-danger-red !important;
    padding:15px;
    box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
    margin:15px 0px;
    border-radius: 10px;
    .message-icon{
        width: 45px;
        height: 45px;
        border: 3px solid $wp-danger-red;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right:15px;
        span{
            font-size:25px;
            color:$wp-danger-red;
            font-weight:600;
        }
    }
}
.message--bubble-notification{
    background-color:$wp-color-secondary;
    color:$wp-color-white !important;
    padding:15px;
    box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
    max-width: 300px;
    border-radius: 10px;
    position:relative;
    &::after{
        content: '';
        position: absolute;
        right: -10px;
        top: 17px;
        width: 0px;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 10px solid $wp-color-secondary;
        border-bottom: 10px solid transparent;
    }
}
.message--confirmation{
    .confirmation-message-inner-container{
        padding:15px;
    }
}
.message-snackbarfull{
    width:100vw !important;
}
.message--selected-time{
    position: fixed;
    right: 6%;
    bottom: 5%;
    background-color:$wp-color-secondary;
    color:$wp-color-white !important;
    padding:10px;
    border-radius:5px;
    z-index:999;
}