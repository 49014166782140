﻿//Selection view styles
.wp-diary-selection-view{
    .wp-table{
	    display: block;
	    width: 100%;
    }
    .wp-table-row {
	    display: flex;
	    position:relative;
        min-height:50px;
        width: fit-content;
        transform-style: preserve-3d;
    }
    .wp-table-row:first-child {
        position: sticky;
	    top:0px;
        z-index: 998;
        background: #f5f5f5;
        box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
    }
    .wp-table-row:nth-child(2) {
      //margin-top:50px;
    }
    .wp-table-cell, .wp-table-head {
	    border: 1px solid #e0e0e0;
	    display: table-cell;
	    padding: 3px 10px;
    }
    .wp-table-foot {
	    background-color: #EEE;
	    display: table-footer-group;
	    font-weight: bold;
    }
    .wp-table-body {
	    display: block;
	    height: 300px;
        max-height: 300px;
        overflow: scroll;
        //width: 1200px;
	    position:relative;
    }
    .resource-column-cell{
	    min-height: 50px;
        width: 250px;
	    min-width:250px;
	    padding:10px;
	    position:sticky;
	    left:0px;
	    background-color: #fff;
	    z-index:99;
        display: flex;
        align-items: center;
        i{
             display:none;
        }
        &.venue-cell{
                font-weight:bold;
                background-color:$wp-background-grey;
                transition:0.3s;
                &:hover{
                    cursor:pointer;
                    background-color:#e0e0e0; 
                }
                i{
                    display:block !important;
                    color:$wp-color-primary;
                    background-color: $wp-color-white;
                    border-radius: 5px;
                }
                .resource-label{
                    padding-left:0px !important;
                }
                .price-label{
                    padding-left:0px !important;
                }
         }
        .price-label{
            font-size:12px;
            padding-left:10px;
        }
        .resource-label{
            //max-width:195px;
            white-space:normal;
            padding-left:10px;
        }

    }
    .timeslot-cell{
	    width: 25px;
	    min-width:25px;
        padding: 0px;
        background-color:#fff;
        padding-bottom: 45px;
        //transition:0.3s;
        &:hover{
            cursor:pointer;
            border: 2px solid #66BB6A;
        }
        &.opt-not-selectable{
            &:hover{
                cursor:not-allowed;
                border:0px;
                border-bottom: 1px solid #e0e0e0 !important;
            }
        }
    }
    .calendar-event-wrap{
        transform: translateZ(-5px);
        transform-style: preserve-3d;
    }
    .hour-cell{
	    width:100px;
	    min-width: 100px;
	    padding:0px;
	    background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .selected-cell{
	    background-color:#66BB6A; 
	    height: 100%; 
        min-width: 25px;
        width: 25px;
    }
    .selected-slots-bar{
	    //background-color:#9B59B6;
	    height:25px;
	    //width:400px;
	    position:absolute;
	    top:0px;
	    //left: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 5px;
        display: flex;
        align-items: center;
        transform: translateZ(5px);
        /*&::after {
                  font-family: 'Material Icons';
                  content: "delete";
                  -webkit-font-feature-settings: 'liga';
                  position: absolute;
                  right: 0;
                  top:0px;
                  color: $wp-txt-primary;
                  height: 22px;
                  cursor: pointer;
                  width: 22px;
                  display: flex;
                  align-items: center;
                  &:hover{
                      color:$wp-danger-red;
                  }
                }*/
        .selected-bar-text{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            padding-right: 15px;
        }
        .delete-timeslot-selection{
            display: flex;
            align-items: center;
            position:absolute;
            background-color:$wp-secondary-green;
            color: $wp-txt-primary;
            position: absolute;
            right: 0;
            max-height:25px;
            transition:0.3s;
            cursor:pointer;
            i{
                color: $wp-txt-primary;
                &:hover{
                 color:$wp-danger-red;
                }
            }
         }
    }
    .opt-not-selectable{
        background-color:$wp-background-grey;
        border:0px;
        border-bottom: 1px solid #e0e0e0 !important;
        /*&:hover{
            cursor:not-allowed;
            border-bottom: 1px solid #e0e0e0 !important;
        }*/
    }
    .opt-selected{
        background-color:$wp-secondary-green;
    }

}
//Weekly view styles
.wp-diary-weekly-view{
    .wp-table{
	    display: block;
	    width: 100%;
    }
    .wp-table-row {
	    display: flex;
	    position:relative;
        min-height:50px;
        width: 100%;
        .resource-column-cell{
            //flex-grow:1;
            width:unset !important;
            max-width:250px;
            i{
                 display:none;
            }
            &.venue-cell{
                font-weight:bold;
                background-color:$wp-background-grey; 
                transition:0.3s;
                &:hover{
                    cursor:pointer;
                    background-color:#e0e0e0; 
                }
                i{
                    display:block !important;
                    color:$wp-color-primary;
                }
                .resource-label{
                    padding-left:0px !important;
                }
                .price-label{
                    padding-left:0px !important;
                }
            }
        }
        .week-day-cell{
            //width: unset;
            flex-grow: 1;
            min-width: 135px;
        }
        .week-timeslot-cell{
            flex-grow: 1;
            min-width: 135px !important;
            //max-width: 250px;
        }
    }
    .wp-table-row:first-child {
        position: sticky;
	    top:0px;
        z-index: 998;
        background: #f5f5f5;
        box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
    }
    .wp-table-row:nth-child(2) {
      //margin-top:50px;
    }
    .wp-table-cell, .wp-table-head {
	    border: 1px solid #e0e0e0;
	    display: table-cell;
	    padding: 3px 10px;
    }
    .wp-table-foot {
	    background-color: #EEE;
	    display: table-footer-group;
	    font-weight: bold;
    }
    .wp-table-body {
	    display: block;
	    height: 300px;
        max-height: 300px;
        border-bottom: 1px solid #e0e0e0;
        overflow-y: scroll;
        position: relative;
    }
    .resource-column-cell{
        min-height: 50px;
        width: 250px;
	    min-width:250px;
	    padding:10px;
	    position:sticky;
	    left:0px;
	    background-color: #fff;
	    z-index:99;
        display: flex;
        align-items: center;
        .price-label{
            font-size:12px;
            padding-left:10px;
        }
        .resource-label{
            //width:200px;
            white-space:normal;
            padding-left:10px;
        }
    }
    .timeslot-cell{
	    width: 25px;
	    min-width:25px;
        padding: 0px;
        background-color:#fff;
    }
    .hour-cell{
	    width:100px;
	    min-width: 100px;
	    padding:0px;
	    background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .week-day-cell{
	    width:150px;
	    min-width: 150px;
	    padding:0px;
	    background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        position:relative;
    }
    .week-timeslot-cell{
	    width: 150px;
	    min-width:150px;
        padding: 0px;
        background-color:#fff;
        transition:0.3s;
        cursor:pointer;
           .wp-txt-body1--xsmall{
               
                    text-align:center
                }
        &:hover{
            //border: 2px solid $wp-color-primary;
            /*background-color:$wp-color-primary;*/
            .week-cell-item{
                background-color:$wp-color-primary;
                .wp-txt-body1--xsmall{
                    color:#fff;
                }
            }
        }
    }
    .selected-cell{
	    background-color:#66BB6A; 
	    height: 100%; 
        min-width: 25px;
        width: 25px;
    }
    .selected-slots-bar{
	    //background-color:#9B59B6;
	    height:25px;
	    //width:400px;
	    position:absolute;
	    top:0px;
	    //left: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 5px;
        display: flex;
        align-items: center;
        /*&::after {
                  font-family: 'Material Icons';
                  content: "delete";
                  -webkit-font-feature-settings: 'liga';
                  position: absolute;
                  right: 0;
                  top:0px;
                  color: $wp-txt-primary;
                  height: 22px;
                  cursor: pointer;
                  width: 22px;
                  display: flex;
                  align-items: center;
                  &:hover{
                      color:$wp-danger-red;
                  }
                }*/
        .delete-timeslot-selection{
            display: flex;
            align-items: center;
            position:absolute;
            background-color:$wp-secondary-green;
            position: absolute;
            right: 0;
            max-height:25px;
            transition:0.3s;
            cursor:pointer;
            i{
                color: $wp-txt-primary;
                &:hover{
                 color:$wp-danger-red;
                }
            }
         }
    }
    .opt-not-selectable{
        background-color:$wp-background-grey;
        border:0px;
        border-bottom: 1px solid #e0e0e0;
    }
    .opt-selected{
        background-color:$wp-secondary-green;
    }
    .week-cell-inner-container{
        padding: 3px;
        >.wp_flex__item:nth-last-child(1){
            margin-bottom:0px !important;
        }
        .week-cell-item--booked{
            background-color:$wp-danger-red;
            margin-bottom: 3px;
            padding:0 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            .wp-txt-body1--xsmall{
                color:$wp-color-white !important;
            }
        }
        .week-cell-item--selected{
            background-color:$wp-secondary-green;
            margin-bottom: 3px;
            padding:0 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            .wp-txt-body1--xsmall{
                color:$base-text-color !important;
            }
        }
        .week-cell-item--special{
            background-color:$wp-background-special-grey;
            margin-bottom: 3px;
            padding:0 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            .wp-txt-body1--xsmall{
                color:$base-text-color !important;
            }
        }
    }
    .wp-week-switcher{
        justify-content:center;
        .mat-form-field{
            position:absolute;
            visibility:hidden;
        }
        .mat-btn-icon{
                border: 1px solid $wp-color-primary;
                border: 1px solid #2574A9;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover{
                    transition:0.3s;
                    .material-icons{
                        color:$wp-color-primary !important;
                    }
                }
            }
    }
    .legend-container{
        margin-top:15px;
        justify-content:space-between;
        margin-bottom: 15px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        .selected-date-legend{
            background-color:$wp-success-green;
            height:15px;
            width:15px;
            margin-right:10px;
        }
        .unavailable-date-legend{
            background-color:$wp-danger-red;
            height:15px;
            width:15px;
            margin-right:10px;
        }
        .special-date-legend{
            background-color:$wp-icon-primary;
            height:15px;
            width:15px;
            margin-right:10px;
        }
    }
}