﻿//Seasons carousel
.wp-carousel{
    .ngucarousel{
        overflow:visible !important;
        .ngucarousel-items{
            flex-wrap: wrap;
            margin: 0 -10px;
        }
        .item{
            flex-grow:inherit !important;
            max-width:500px;
            display:flex;
            @include respond-to('x-small'){
                margin-bottom:10px;
            }
            @include respond-to('medium'){
                margin-bottom:0px;
            }
        }
    }
    .wp-seasons-card-wrapper{
        display:flex;
        width:100%;
        .wp-seasons-card{
            width:100%;
            min-height:161px;
            max-width:410px;
            background-color:#ffffff;
            border-radius:10px;
            position:relative;
            padding:15px;
            margin:0px 10px;
            box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
            cursor:pointer;
            transition:0.3s;
            &:hover{
                transform: scale(1.05);
                position:relative;
                z-index:999;
            }
        }
        .card-selection{
            display:flex;
            align-items:center;
            justify-content:center;
            border-radius:50%;
            border:1px solid $wp-color-primary;
            height: 25px;
            width: 25px;
            position: absolute;
            top: 15px;
            right: 15px;
            
            i{
                display:none;
            }
            &.selected{
                background-color:$wp-color-primary;
                i{
                    display:block !important;
                    color:#fff !important;
                    font-size:16px !important;
                }
            }
        }
    }
    .right-nav-icon{
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:50%;
        border:1px solid $wp-color-primary;
        background-color:$wp-color-white;
        height: 30px;
        width: 30px;
        cursor:pointer;
        position: absolute;
        right:-24px;
        top:65px;
        z-index:998;
    }
    .left-nav-icon{
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:50%;
        border:1px solid $wp-color-primary;
        background-color:$wp-color-white;
        height: 30px;
        width: 30px;
        cursor:pointer;
        position: absolute;
        left:-24px;
        top:65px;
        z-index:998;
    }
    .myPoint{
        display:flex;
        justify-content:center;
        margin-top:10px;
        li{
            color:#bfbfbf;
            width:20px;
            &.active{
                color:$wp-color-primary;
            }
        }
    }
}

.opt-regular-booking {
    .mat-horizontal-content-container {
        overflow: hidden !important;
    }
    .wp-rollover-booking-modal {
        .term-card {
            overflow: hidden;
            padding: 15px;
            }
        }
    
    .ngucarousel {
        .ngucarousel-items {
            flex-wrap: nowrap !important;
        }
    }
}
