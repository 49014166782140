﻿.recurring-booking-popup{
    .recurring-pattern-wrapper{
        background-color: $wp-background-grey;
        padding:15px;
        .recurring-pattern-header{
            background-color:#ffffff;
            padding:15px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items:center;
            box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
            .mat-radio-label{
                margin-bottom:0px;
            }
        }
        
    }
    .mat-radio-label {
        width: 83% !important;
    }
    .range-wrapper {
        .mat-radio-label {
            width: 100% !important;
        }
    }
    .weekly-inner{
        .mat-radio-label {
            width: 100% !important;
        }
    }
    .daily-inner{
        .mat-radio-label {
            width: 100% !important;
        }
    }
    .mat-radio-label-content{
        display:flex;
        align-items:center;
        justify-content: space-between;
        width: 100%;
    }
    .daily-inner{
        background-color:$wp-color-white;
        padding:15px;
        .mat-radio-group{
            display:flex;
            flex-direction:column;
        }
        .everyday-input{
            max-width:50px;
            margin:0 10px;
        }
    }
    .weekly-inner{
        background-color:$wp-color-white;
        padding:15px;
        .mat-checkbox{
            margin-right:40px;
        }
        .every-week-input{
            max-width:50px;
            margin:0 10px;
        }
        .checkbox-section{
            display: flex;
            flex-wrap: wrap;
            margin-top:10px;
            .mat-checkbox{
                width:100px;
            }
        }
    }
    .monthly-inner{
        background-color:$wp-color-white;
        padding:15px;
        .mat-form-field{
            margin: 0 8px;
            width: 20% !important;
            flex: 0 0 20%;
            display: flex !important;
            justify-content: space-between;
        }
        .day-of-month-input{
            max-width:50px;
        }
        .monthly-day-field{
            width:100px;
        }
    }
    @media (max-width: 690px) {
        .overflowScrollSec{
            overflow-y: scroll;
        }
    }
    .range-wrapper{
        background-color: #f5f5f5;
        padding: 15px;
        margin-top:15px;
        //height: 100%;
        display: flex;
        flex-direction: column;
        //margin-bottom:15px;
        .mat-form-field{
            max-width: 150px;
            margin: 0 5px;
        }
        .range-body{
            background-color: #ffffff;
            padding:15px;
            flex-grow: 1;
            .mat-radio-group{
                .mat-radio-button{
                    margin-bottom:10px;
                    &:nth-last-child(1){
                        margin-bottom:0px;
                    }
                }
            }
        }
        .occurence-input{
            max-width:50px;
            margin:0 10px;
        }
    }
    .recurring-left-col-wrapper{
        max-height:435px;
        display:flex;
        flex-direction:column;
        //justify-content:space-between;
    }
    .recurring-right-col-wrapper{
        background-color:$wp-background-grey;
        padding:15px;
        height:100%;
        overflow-y:auto;
    }
    .view-dates-container{
        padding: 15px;
        background-color: $wp-color-white;
        border: 1px solid $wp-background-dark-grey;
        border-radius: 5px;
    }
    .mat-tab-body{
        .mat-tab-body-content{
            box-shadow:unset;
            overflow:hidden;
        }
        .dates-selected-container{
            background-color: $wp-background-grey;
            .selected-date-row{
                background-color: #ffffff;
                padding: 10px;
                margin-bottom:5px;
                /*i{
                    color:$wp-icon-primary;
                    font-size:20px;
                    cursor:pointer;
                }*/
            }
        }
        .mat-paginator{
                width:100%;
                background-color:$wp-color-white;
            }
    }

    .mat-form-field-wrapper{
        width: 100%;
    }
}
.recurring-booking-popup .range-body .mat-radio-label-content {
    justify-content: unset !important;
}