﻿.wp-location-resource-search{
    .search-panel{
        background-color:#ffffff;
        border-radius:10px;
        padding:15px;
        margin-bottom:30px;
        box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
        .mat-form-field{
            width:100%;
            .mat-form-field-infix{
                line-height: 20px;
            }
            .mat-select-panel {
                min-width: 112px !important;
            }
        }
         //this style only applying for filter-by-venue
        .cdk-overlay-container .cdk-overlay-pane .mat-select-panel.filter-by-venue-panel {
            min-width: 112px !important;
        }

    }
    .search-results-count{
        margin-bottom:15px;
    }
    .facility-selection-row{
        background-color: #ffffff;
        padding: 15px;
        margin-bottom:5px;
        &:nth-last-child(1){
            margin-bottom:0px;
        }
        img{
            width: 20px;
            //height: 20px;
            margin: 0 5px;
        }
        a{
            white-space:normal;
        }
    }
    .range-selector-container{
        position:relative;
        top:-8px;
        .placeholder-label{
            font-size:10px;
        }
    }
}

.search-pagi {
    .mat-paginator {
        .mat-paginator-outer-container {
            .mat-paginator-container {
                .mat-paginator-page-size {
                    .mat-paginator-page-size-label {
                        // flex: 0 0 45%;
                    }
                    .mat-paginator-page-size-select {
                        width: 56px !important;
                    }
                }
            }
        }
    }
}

.room-drop{
    padding: 5px 5px 0 5px;
    width: 15%;
    .room-drop-inner{
        width: 15%;
    }
}

.cdk-overlay-pane{
    overflow-x: hidden;
}

.top-padding{
    padding-top: 5px;
}

.facility-selection-inner-row{
    display: flex;
    justify-content: space-between;
}

.max-capacity{
    white-space: nowrap;
}