﻿/*@import "./_variables.scss";*/
@import "./_mixins.scss";

.mat-button{
  
}





//================= OPTIMO BUTTONS ================/

%btn{
  height: 35px;
  padding: 0 15px;
  width: auto;
  border-radius:4px !important;
  transition:0.3s !important;
  line-height: 14px !important;
}

%btn-small{
  height: 30px;
  padding: 0 15px;
  width: auto;
  border-radius:4px !important;
  transition:0.3s !important;
  line-height: 12px !important;
}

.mat-btn{
  &-primary { /*default*/
    @extend %btn;
    @include box_shadow (1);
    color: $wp-txt-secondary !important;
    text-align: center !important;
    transition:0.3s !important;
    &:hover{
      background-color: $wp-color-primary;
      color: $wp-color-white !important;
    }

    &--small {
      @extend %btn-small;
      @include box_shadow (1);
        color: $wp-txt-secondary !important;
        text-align: center !important;
        font-size: 14px !important;
      &:hover{
        background-color: $wp-color-primary !important;
        color: $wp-color-white !important;
      }
    }
  }
  &-icon{
    min-width: unset;
    padding: 0px;
    color:$wp-icon-primary;
    @include box_shadow (1);
    .mat-button-wrapper{
        width: 40px !important;
        height: 40px;
        min-width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
  

}
