﻿

.mat-dialog-container{
    .mat-dialog-content{
      .modal-header{
        button.mat-button:focus{
            border: unset !important;
        }
      }
    }
  }
  // --------------------------- Legacy CSS ------------------------

.w90modal{
    width: 90% !important;
    max-width: 90% !important;
}
.wp-modal-header{
    display:flex;
    align-items:center;
    height:55px;
    background-color:#ffffff;
    padding:15px;
    box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
    position: relative;
    z-index: 999;
    button{
        background-color: transparent;
        box-shadow: none;
        border: none;
        display: flex;
        align-items: center;
    }
}
.wp-modal-body{
    background-color:#ffffff;
    padding:25px 15px;
    max-height: 80vh;
    overflow-y: scroll;
}
.wm-facility-config{
    .mat-select {
        width: 50% !important;
    }
}
::ng-deep .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination.mat-tab-header-pagination{
    @media screen and (min-width:1024px) {
        display: none !important;
    }
}
.wp-modal-footer{
    background: $wp-background-grey;
    padding: 15px;
    position: sticky;
}
.wp-modal-buttons{
    display:flex;
    align-items:center;
    justify-content:flex-end;
    box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
    position: relative;
    padding:0 15px;
    z-index: 999;
    height:55px;
    background-color:#ffffff;
}

//Pricing Modal
.wp-pricing-modal{
    .wp-modal-body{
        background-color:$wp-background-grey;
        padding:15px;
    }
    .pricing-container{
        background-color: $wp-background-grey;
        padding: 0px;
        >.wp_flex--container{
            &:nth-last-child(1){
                margin-bottom:0px !important;
            }
        }
        .pricing-row-container{
            padding:15px;
            background-color:$wp-color-white;
            /*margin-bottom:10px;*/
            >.wp_flex__item--inherit{
                flex-grow:1;
                max-width:300px;
                &:nth-child(1){
                    flex-grow:inherit !important;
                }
            }
        }
    }
}
//Ad hoc date selection modal
.wp-adhoc-date-selection{
    .adhoc-left-col-wrapper{
        background-color: $wp-background-grey;
        padding: 15px;
    }
    .adhoc-right-col-wrapper{
        background-color: $wp-background-grey;
        padding: 15px;
        height:100%;
    }
    .mat-calendar{
        width:300px !important;
        background-color:#ffffff;
        box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
        th{
            text-align:center;
        }
    }
    .mat-calendar-table-header-divider{
        border-bottom:0px;
    }
    .dates-selected-container{
            .selected-date-row{
                background-color: #ffffff;
                padding: 10px;
                margin-bottom:5px;
                i{
                    color:$wp-icon-primary;
                    font-size:20px;
                    cursor:pointer;
                }
            }
            .mat-paginator{
                width:100%;
                background-color: $wp-color-white;
            }
        }
    .legend-container{
        justify-content:space-between;
        margin-bottom: 15px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    .selected-date-legend{
        background-color:$wp-success-green;
        height:15px;
        width:15px;
        margin-right:10px;
    }
    .unavailable-date-legend{
        background-color:$wp-danger-red;
        height:15px;
        width:15px;
        margin-right:10px;
    }
    .special-date-legend{
        background-color:$wp-icon-primary;
        height:15px;
        width:15px;
        margin-right:10px;
    }
    .adhoc-help-text-container{
        padding: 15px;
        background-color: $wp-color-white;
        border: 1px solid $wp-background-dark-grey;
        border-radius: 5px;
    }
}

//Booking Details modal
.wp-booking-details-modal{
    @media screen and (max-width:1200px) {
        .wp-txt-body1 {
            font-size: 14px !important;
        }
        .wp-txt-body2 {
            font-size: 14px !important;
        }
    }
    .facility-panel{
        //margin-bottom:10px !important;
        .facility-panel-row{
            margin: 0px;
            justify-content: space-between;

            @media screen and (max-width:1200px) {
                .wp-txt-body1 {
                    font-size: 14px !important;
                }
                .wp-txt-body2 {
                    font-size: 14px !important;
                }
            }
        }
    }
    .booking-details-header-title-container{
        overflow:hidden;
    }
    .booking-details-header-title{
        white-space:nowrap;
    }
    .mat-expansion-panel-header{
        &.mat-expanded{
            .booking-details-header-title-container{
                overflow:visible;
            }
            .booking-details-header-title{
                white-space:normal;
            }
        }
    }
    .facility-timeslot-row{
        background-color: #fff;
        padding: 15px;
        margin-bottom: 5px;
        border-left: 5px solid $wp-secondary-green;
        &.conflict-row{
            color:$wp-danger-red !important;
            border-left: 5px solid $wp-danger-red !important;
        }
        .alternate-options-row{
            background-color: #f5f5f5;
            padding: 5px;
            margin-top: 15px;
        }
    }
    .alternate-options-container{
        background-color:#fff;
        padding:15px;
        margin-bottom: 20px;
        .mat-radio-group{
            display:flex;
            flex-direction:column;
        }
    }
    .add-extras-container{
        background-color:$wp-color-white;
        padding:15px;
        border-radius:5px;
        margin-bottom: 20px;
        margin-top:20px;
        .extras-row-wrapper{
            background-color:$wp-background-grey;
            padding:15px;
            border-radius:5px;
        }
        .extras-row{
            background-color:$wp-color-white;
            padding:10px;
            margin-bottom:5px;
            &:nth-last-child(1){
                margin-bottom:0px;
            }
        }
    }
    .wp-txt--danger {
        color: #DC2A2A !important;
    }
    .rb-date-time-wrap {
        display: flex;
        width: 100%;
    }
    .rb-facility-name{
        width: 40%;
        @media screen and (max-width:1227px) and (min-width:1200px) {
            width: 41% !important;
        }
        @media screen and (max-width:1200px) {
            width: 50% !important;
            .wp-txt-body1 {
                font-size: 14px !important;
            }
        }
    }
    .rb-facility-config{
        width: 15%;
        padding-left: 10px;
    }
    .rb-facility-availability{
        width: 18%;

        @media screen and (max-width:1262px) and (min-width:1200px) {
            width: 19% !important;
        }
        @media screen and (max-width:1200px) {
            width: 20% !important;
        }

    }
    .rb-facility-recurring{
        width: 10%;

        @media screen and (max-width:1200px) {
            width: 8% !important;
        }
    }
    .rb-facility-delete{
        width: 5%;
    }
    .rb-time-range{
        flex-direction: row !important;
        align-items: center;
        color: #797070;
    }
    .rb-time-range::before{
        content: '|';
        padding: 0 10px 0 10px;
    }
}
.mat-horizontal-stepper-content{
    .rb-facility-name{
        width: 50%;
        @media screen and (max-width:1227px) and (min-width:1200px) {
            width: 50% !important;
        }
        @media screen and (max-width:1200px) {
            width: 50% !important;
            .wp-txt-body1 {
                font-size: 14px !important;
            }
        }
    }
    .rb-facility-config{
        width: 15%;
        padding-left: 10px;
        @media screen and (max-width:1227px) and (min-width:1200px) {
            width: 16% !important;
        }
        @media screen and (max-width:1200px) {
            padding-left: 5px;
        }
    }
    .rb-facility-availability{
        width: 20%;

        @media screen and (max-width:1262px) and (min-width:1200px) {
            width: 21% !important;
        }
        @media screen and (max-width:1200px) {
            width: 23% !important;
        }

    }
    .rb-facility-recurring{
        width: 10%;

        @media screen and (max-width:1262px) and (min-width:1200px) {
            width: 9% !important;
        }
        @media screen and (max-width:1200px) {
            width: 8% !important;
        }
    }
    .rb-facility-delete{
        width: 1%;
    }
}
//Booking Details Pattern Selection Modal
.wp-booking-details-pattern-modal{
    .mat-tab-group{
       .mat-tab-label{
           display:none;
       }
    }
    .mat-tab-group{
       .mat-tab-header{
           border-bottom:0px;
           .mat-tab-header-pagination-controls-enabled {
            .mat-tab-header-pagination{
                display: none !important;
            }
           }
           
       }
    }
}

//Resolve Conflicts Modal
.wp-resolve-conflicts-modal{
    .available-timeslots-container{
        background-color:$wp-background-grey;
        padding:15px;
        .chip-container{
            flex-wrap:wrap;
            >.wp_flex__item--inherit{
                width: calc(96% / 3);
                margin-right: 5px;
                margin-bottom: 5px;
            }
            .chip-select{
                padding:10px 15px;
                color:$wp-success-green;
                cursor:pointer;
                background-color:$wp-color-white;
                transition:0.3s;
                border-radius:10px;
                box-shadow: 0 0px 15px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
                &:hover{
                    background-color:$wp-success-green;
                    color:$wp-color-white;
                }
                &.selected{
                    background-color:$wp-success-green;
                    color:$wp-color-white;
                    padding: unset !important;

                    span{
                        padding: 15px 0 !important;
                        text-align: center !important;
                        display: flex !important;
                        align-items: center !important;
                        justify-content: center !important;
                    }
                }
            }
        }
    }
    .adhoc-left-col-wrapper{
            @include respond-to('x-small'){
                margin-bottom:10px;
            }
            @include respond-to('large'){
                margin-bottom:0px;
            }
    }

    .timepicker-wrapper{
        margin-bottom:20px;
    }
    .timepicker-container{
        padding-right:5px;
        padding-left:5px;
        padding-top:20px;
        background-color:white;
        margin-right:20px;
        height:150px;
    }
}

//Rollover booking modal
.wp-rollover-booking-modal{
    .wp-txt-title{
        font-size:18px !important;
        font-weight: 600 !important;
    }
}

.rollover-items{
    display:block !important;

}
.item-count{
    margin-left: 24px;
    font-size: 13px !important;
}

.search-pagi {
    .mat-paginator {
        .mat-paginator-outer-container {
            .mat-paginator-container {
                .mat-paginator-page-size {
                    .mat-paginator-page-size-label {
                        // flex: 0 0 45%;
                    }
                    .mat-paginator-page-size-select {
                        width: 56px !important;
                    }
                }
            }
        }
    }
}