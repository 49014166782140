$preferred_font: 'Muli', sans-serif !default;

//Text colors
$base-text-color: #000000;
$wp-txt-primary: #373D3F;
$wp-txt-secondary: #707070;
$wp-txt-tertiary: #2574A9;
$wp-txt-quaternary: #AA5D00;
$wp-txt-danger: #DC2A2A;

//Icon colors
$wp-icon-primary:#BFBFBF;

//Colors
$wp-color-primary:#2574A9;
$wp-color-secondary:#9B59B6;
$wp-color-tertiary:#AA5D00;
$wp-background-grey:#f5f5f5;
$wp-background-dark-grey:#ccc;
$wp-background-special-grey:#bfbfbf;
$wp-color-white:#ffffff;
$wp-success-green:#2E7D32;
$wp-secondary-green:#66BB6A;
$wp-danger-red:#DC2A2A;
/*$wp-color-primary-orange:#AA5D00;
$wp-color-primary-purple:#9B59B6;*/

$wp-color-1:#66BB6A;
$wp-color-2:#7E57C2;
$wp-color-3:#FFA726;