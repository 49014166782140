﻿.all-popups{
    button{
        margin:15px;
    }
}
.notes-popup{
    width: 500px !important;
    h4{
        margin-bottom:10px;
    }
    .notes-container{
        padding: 15px;
        border: 1px solid #bfbfbf;
    }
    .notes-team-title{
        margin-bottom:5px;
    }
}
.standard-items-popup{
    width: 500px !important;
    min-width:500px;
    h4{
        margin-bottom:10px;
    }
    .standard-items-container{
        padding: 15px;
        border: 1px solid #bfbfbf;
    }
    .standard-items-team-title{
        margin-bottom:5px;
    }
}
.dates-popup{
    width:400px !important;
    .wp_flex__item{
        max-width:170px;
    }
    .year-container{
        margin-bottom:10px;
        margin-left:10px;
    }
    .month-container{
        margin-bottom:10px;
        margin-left:20px;
    }
    .day-container{
        margin-bottom:10px;
        margin-left:30px;
    }
}
.range-popup{
    padding:20px;
    .mat-menu-content{
        padding-top:8px !important;
        padding-bottom:0px !important;
    }
}
.time-range-popup{
    padding:20px;
    min-width:300px !important;
    .mat-form-field{
        width:100%;
    }
    .mat-menu-content{
        padding-top:8px !important;
        padding-bottom:0px !important;
    }
}
.time-range-menu{
    table{
        margin:0px;
        tbody{
            border:none !important;
        }
    }
    timepicker{
        button{
            &.btn{
                background-color:$wp-color-primary !important;
            }
        }
    }
}