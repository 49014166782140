﻿.header__user{
    .header-nav-venue, .header-nav-rb{
        &:before{
            background-color: #c9da43 !important;
            color: #000 !important;
        }
        &:hover{
            &:before{
                background-color: #000 !important;
                color: #c9da43 !important;
            }
        }
    }
    
  //   @media screen and (max-width: 909px) {
  //     .user{
  //       height: auto !important;
  //     }
  // }
  .waste-header-flex {
    display: flex;
    width: auto;
  }
}

// @media screen and (max-width: 909px) {
//   .sub_left{
//     width: 100% !important;
//     border-bottom: 1px solid #b7b7b7;
//   }
//   .sub{
//     width: 100% !important;
//     margin-top: 7px;
//   }
// }

.sub-header-user {
    @media screen and (max-width: 577px) {
        margin-bottom: 0px !important;
        z-index: unset !important;
    }
}



   %header_nav-icon-cover{
     height: 70px;
     min-width: 50px;
     padding-right: 10px;
     display: flex;
     align-items: center;
     transition: 0.3s;
     text-decoration: unset !important;
     color: #2574A9 !important;
     
     @media screen and (max-width:768px) {
      padding-bottom: 5px;
     }
   }
   %header_nav-icon-before{
     font-family: "Material Icons";
     transition: 0.3s;
     margin-right: 5px;
     border: 1px solid;
     border-radius: 50%;
     padding: 5px;
     height: 25px;
     width: 25px;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: larger !important;

     &hover{
      background-color: #000 !important;
      color: #c9da43 !important;
     }
   }

  %header_nav-hover{
     background-color: #787272;
     color: #fff;
  }


  .header__alter-menu{
      .sub_left {
           .wm-search_customer {
              @extend %header_nav-icon-cover;
              margin-left: 20px;
              &:focus {
                outline: 2px solid black;
              }
             &:before {
               content: 'group';
               @extend %header_nav-icon-before;
             }

             &:hover {
               &:before {
                 @extend %header_nav-hover;
               }
             }
           }
        .wm-new_customer {

          @extend %header_nav-icon-cover;

          &:before {
            content: 'person_add';
            @extend %header_nav-icon-before;
          }

          &:hover {
            &:before {
              @extend %header_nav-hover;
            }
          }
        }
        .wm-selected-customer-profile{
             @extend %header_nav-icon-cover;

          //  &:before {
          //   content: 'person';
          //   padding-left: 7px;
          //   @extend %header_nav-icon-before;
          //  }

          &:hover {
            &:before {
              @extend %header_nav-hover;
            }
          }
        }
        .wm-go-home {

          @extend %header_nav-icon-cover;

          &:before {
            content: 'home';
            @extend %header_nav-icon-before;
            background-color: #c9da43 !important;
            color: #000 !important;
          }

          &:hover {
            &:before {
              @extend %header_nav-hover;
            }
          }
          &:after {
            @media only screen and (max-width: 768px) {
              content: '';
              padding-left: 10px;
            }
          }
        }
      }
      .sub_left {
        @media only screen and (max-width: 768px) {
          border-bottom: 1px solid #b7b7b7;
          min-height: 70px;
          width: 100% !important;
        }
      }
      .sub {
        @media only screen and (max-width: 768px) {
          min-height: 70px;
        }
      }
  }
  // @media (max-width: 909px){
  //   .header__alter-menu{
  //     display: flex;
  //     flex-direction: column !important;
  //     justify-content: start;
  //   }
  // }
  .wm-login-user-profile-icon-wrapper{
     position: relative;

       .wm-signIn-user-icon{
          cursor: pointer;
          @extend %header_nav-icon-cover;

          &:before {
            content: 'person';
            @extend %header_nav-icon-before;
          }

          &:hover {
            &:before {
              @extend %header_nav-hover;
            }
          }
     }

    .wm-dashboard-icon{

      @extend %header_nav-icon-cover;
      cursor: pointer;
      &:before {
        content: 'apps';
        @extend %header_nav-icon-before;
      }

      &:hover {
        &:before {
          @extend %header_nav-hover;
        }
      }
    }

     .wm-profile-img{
          padding-right: 15px;
     }
     .wm-profile-avatar{
         height: 35px;
         width: 35px;
         margin-right: 20px;
         border-radius: 50px;
         overflow: hidden;
         display: flex;
         align-items: center;
         justify-content: center;
        //  margin-top: 17px;

         img {
               height: 35px;
               width: 35px;
         }
     }
     @media (max-width: 1024px){
        .wm-profile-avatar{
          margin-top: 0px !important;
        }
     }
     .wm-selected-cus-img{
        margin-right: 7px !important;
     }
     .wm-relative-img{
        position: relative;
        left: 7px;
     }

  }

.wm-home-cart {
  @include respond-to('x-small') {
    position: unset !important;
  }

  @include respond-to('small') {
    position: unset !important;
  }

  @include respond-to('medium') {
    flex-grow: inherit !important;
  }

  @include respond-to('large') {
    flex-grow: inherit !important;
  }

}

.navbar-light .sub .mmc_flex__item--inherit {
  &.align--center {
    &.wm-name-left {
      @include respond-to('x-small') {
        padding-left: 0 !important;
        border-right: 1px solid #b7b7b7 !important;
        border-left: none !important;
      }

      @include respond-to('small') {
        padding-left: 0 !important;
        border-right: 1px solid #b7b7b7 !important;
        border-left: none !important;
      }

      @include respond-to('medium') {
        padding-left: 0;
        border-right: none !important;
      }

      @include respond-to('large') {
        padding-left: 0;
        border-right: none !important;
      }
    }
  }
}

.wm-header-item {
  display: none;
}

.wm-top-name {
  @include respond-to('x-small') {
    display: flex;
    justify-content: flex-start !important;
  }

  @include respond-to('small') {
    display: flex;
    justify-content: flex-start !important;
  }

  @include respond-to('medium') {
    justify-content: flex-end !important;
  }

  @include respond-to('large') {
    justify-content: flex-end !important;
  }

}

.wm-sub-d-flex {
  width: 100%;
}

.wm-header-upper {
  @include respond-to('x-small') {
    padding-left: 25px;
  }

  @include respond-to('small') {
    padding-left: 25px;
  }

  @include respond-to('medium') {
    position: unset;
  }

  @include respond-to('large') {
    position: unset;
  }

}

.wm-wp-mr {
  @include respond-to('x-small') {
    width: unset !important;
  }

  @include respond-to('small') {
    width: unset !important;
  }

  @include respond-to('medium') {
    width: unset;
    min-width: fit-content;
  }

  @include respond-to('large') {
    width: unset;
    min-width: fit-content;
  }

}

.wm-header-search {
  @include respond-to('x-small') {
    margin-left: unset !important;
  }

  @include respond-to('small') {
    margin-left: unset !important;
  }

  @include respond-to('medium') {
    margin-left: 20px !important;
  }

  @include respond-to('large') {
    margin-left: 20px !important;
  }
}

.wm-header-customer {
  @include respond-to('x-small') {
    padding-left: 15px;
    padding-right: 0 !important;
  }

  @include respond-to('small') {
    padding-left: 15px;
    padding-right: 0 !important;
  }

  @include respond-to('medium') {
    padding-left: 15px;
    padding-right: 0 !important;
  }

  @include respond-to('large') {
    padding-left: 15px;
    padding-right: 0 !important;
  }
}

.wm-go-back {
  @include respond-to('x-small') {
    position: absolute;
    top: -160px;
    z-index: 1000;
    left: 215px;
  }

  @include respond-to('small') {
    position: absolute;
    top: -160px;
    z-index: 1000;
    left: 215px;
  }

  @include respond-to('medium') {
    position: unset;
  }

  @include respond-to('large') {
    position: unset;
  }
}

.wm-customer {
  min-width: fit-content;
}

.wm-select-profile {
  height: 63px !important;
  border: 1px solid lightgrey;
  background-color: #f2f2f2;
  border-radius: 3px;
  position: relative;
  top: -4px;
  margin: 0px 10px;
}

.wm-header-close-btn {
  font-size: 16px !important;
}

@media screen and (max-width: 768px){
  .past-booking .navbar-collapse .header__alter-menu .sub_left span {
    height: auto !important;
  }
  .header__user .waste-header-flex {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: space-between;

    .wp-mr-20{
      padding-top: unset !important;
    }
  }
}
.wm-sign-in-btn{
  @media screen and (max-width:400px) {
    width: 88% !important;
    display: flex;
    justify-content: space-between; 
  }
  button {
    display: flex;
    margin-right: 15px;
    width: 42px !important;
    min-width: unset;
    @media screen and (max-width:768px) {
      margin-right: 0px;
      justify-content: end !important;
    }
}
  @media only screen and (max-width: 768px) {
    div:nth-child(1){
      width: 80%;
    }    
  }
    button i {
      margin: 0px !important;
      padding: 0px 12px !important;
  }
}
.mmc_enquiry{
  .bottom-line{
    .bottom-line--topic {
      .line {
        position: relative;
        top: 10px;
      }
    }
  }
}

span.nameEle {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 12ch !important;
}