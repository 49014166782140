/*$wp-color-primary-orange:#AA5D00;
$wp-color-primary-purple:#9B59B6;*/
@import url(~@angular/material/prebuilt-themes/indigo-pink.css);
@import url(~animate.css/animate.min.css);
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
/*@import "abstracts/_variables.scss";*/
/*@import "./_variables.scss";*/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mat-btn-primary, opt-regular-booking .mat-btn-primary, .opt-regular-booking .mat-btn-primary, opt-regular-booking .mat-btn-secondary, .opt-regular-booking .mat-btn-secondary, opt-regular-booking .mat-btn-tertiary, .opt-regular-booking .mat-btn-tertiary {
  height: 35px;
  padding: 0 15px;
  width: auto;
  border-radius: 4px !important;
  transition: 0.3s !important;
  line-height: 14px !important;
}

.mat-btn-primary--small, opt-regular-booking .mat-btn-primary--small, .opt-regular-booking .mat-btn-primary--small, opt-regular-booking .mat-btn-secondary--small, .opt-regular-booking .mat-btn-secondary--small, opt-regular-booking .mat-btn-tertiary--small, .opt-regular-booking .mat-btn-tertiary--small {
  height: 30px;
  padding: 0 15px;
  width: auto;
  border-radius: 4px !important;
  transition: 0.3s !important;
  line-height: 12px !important;
}

.mat-btn-primary {
  /*default*/
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: #707070 !important;
  text-align: center !important;
  transition: 0.3s !important;
}

.mat-btn-primary:hover {
  background-color: #2574A9;
  color: #ffffff !important;
}

.mat-btn-primary--small {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: #707070 !important;
  text-align: center !important;
  font-size: 14px !important;
}

.mat-btn-primary--small:hover {
  background-color: #2574A9 !important;
  color: #ffffff !important;
}

.mat-btn-icon {
  min-width: unset;
  padding: 0px;
  color: #BFBFBF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mat-btn-icon .mat-button-wrapper {
  width: 40px !important;
  height: 40px;
  min-width: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Muli", sans-serif;
}

::ng-deep .cdk-overlay-container .cdk-overlay-pane.mat-select-panel {
  min-width: 100% !important;
}

.mat-select-panel {
  min-width: 100% !important;
}

.wp_flex--container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.wp_flex--container.space-between {
  justify-content: space-between !important;
}

.wp_flex__item {
  -webkit-align-self: auto;
  -moz-align-self: auto;
  -ms-align-self: auto;
  align-self: auto;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.wp_flex__item--inherit {
  flex-grow: inherit;
}

.wp_flex__item--vcenter {
  display: flex;
  align-items: center;
}

.wp_flex__item--vbottom {
  display: flex;
  align-items: flex-end;
}

.wp_flex__item.p5 {
  width: 5%;
}

.wp_flex__item.p10 {
  width: 10%;
}

.wp_flex__item.p15 {
  width: 15%;
}

.wp_flex__item.p20 {
  width: 20%;
}

.wp_flex__item.p25 {
  width: 25%;
}

.wp_flex__item.p30 {
  width: 30%;
}

.wp_flex__item.p33 {
  width: 33%;
}

.wp_flex__item.p40 {
  width: 40%;
}

.wp_flex__item.p50 {
  width: 50%;
}

.wp_flex__item.p70 {
  width: 70%;
}

.wp_flex__item.p75 {
  width: 75%;
}

.wp_flex__item.p100 {
  width: 100%;
}

.wp--flex-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-content: stretch;
  -moz-align-content: stretch;
  -ms-align-content: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.wp--depth__1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.wp--depth__2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.wp--depth__3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.wp--depth__4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.wp--depth__5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.container-fluid {
  padding: 0px;
}

.__link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.mat-sidenav-container {
  position: absolute !important;
  z-index: 1000 !important;
}

.mat-sidenav-container .mat-drawer-inner-container {
  overflow: unset;
  overflow-x: visible;
}

.mat-sidenav-container .mat-sidenav {
  height: 100vh;
  width: 530px;
  padding: 15px;
  background-color: #f5f5f5;
  overflow: visible;
  position: fixed;
  overflow-y: auto;
}

.mat-sidenav-container .arrow-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #2574A9;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 25px;
}

.mat-sidenav-container .btn-sidepanel-open {
  position: fixed;
  top: 15%;
  bottom: 50%;
  right: 0px;
  width: 70px;
  height: 550px;
  min-width: unset !important;
  border-radius: 10px 0px 0px 10px !important;
  padding: 10px;
}

.mat-sidenav-container .btn-sidepanel-open:hover {
  background-color: #f5f5f5 !important;
  border: 1px solid #2574A9;
  color: #2574A9 !important;
  cursor: pointer;
}

.mat-sidenav-container .btn-sidepanel-open .mat-button-wrapper {
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;
}

.mat-sidenav-container .sidepanel-btn-summary {
  transform: rotate(-90deg);
  position: absolute;
  right: -236px;
  top: 50%;
  width: 520px;
  height: 40px;
}

.mat-sidenav-container .sidepanel-btn-summary .mat-btn-icon {
  margin: 0 10px;
  border: 1px solid #2574A9;
  border-radius: 4px;
}

.mat-sidenav-container .sidepanel-btn-summary .mat-button-wrapper {
  width: 25px !important;
  height: 25px;
}

.mat-sidenav-container .sidepanel-btn-summary .team-label {
  max-width: 120px;
  color: #2574A9 !important;
}

.mat-sidenav-container .btn-sidepanel-close {
  /*position: absolute;
        left: -30px;
        top: 50%;
        bottom: 50%;
        width: 30px;
        height: 100px;
        min-width: unset;
        border-radius: 10px 0px 0px 10px !important;
        padding: 0px;*/
  width: 30px;
  min-width: 30px !important;
  height: 30px;
  border-radius: 50% !important;
  background-color: #2574A9 !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 515px;
  top: 50%;
  bottom: 50%;
  padding: 0px;
  min-width: unset;
}

.mat-sidenav-container .btn-sidepanel-close .mat-button-wrapper i {
  color: #ffffff;
}

.mat-sidenav-container .sidepanel-header {
  background-color: #ffffff;
  margin-bottom: 10px;
}

.mat-sidenav-container .sidepanel-header .mat-btn-icon {
  margin: 0 10px;
  border: 1px solid #2574A9;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
}

.mat-sidenav-container .sidepanel-header .select-team-dropdown {
  margin-top: 15px;
  margin-bottom: 5px;
}

.mat-sidenav-container .sidepanel-header .select-team-dropdown .mat-form-field {
  width: 100%;
}

.mat-sidenav-container .sidepanel-header .select-team-dropdown .mat-form-field .mat-select-value-text {
  color: #2574A9;
  font-size: 18px;
  font-weight: 500;
}

.mat-sidenav-container .sidepanel-header .sidepanel-team-selection-container {
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

.mat-sidenav-container .sidepanel-expanded-row {
  background-color: #ffffff;
  padding: 10px 0px;
  margin: 0px;
  margin-bottom: 5px;
}

.mat-expansion-panel {
  position: relative;
  z-index: 995;
  box-sizing: border-box !important;
}

.mat-expansion-panel .mat-expansion-panel-header {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0 20px;
  min-height: 55px !important;
  height: auto !important;
  position: relative;
  z-index: 996;
}

.mat-expansion-panel .mat-expansion-panel-body {
  background-color: #f5f5f5;
  padding: 15px;
}

.mat-primary .mat-pseudo-checkbox-checked {
  background-color: #2574A9;
}

.mat-primary .mat-pseudo-checkbox-checked::after {
  color: #fff;
  top: 4.4px;
  left: 2px;
}

.wp-chip-primary {
  background-color: #BFBFBF;
  color: #fff;
  padding: 0px 10px;
  border-radius: 12px;
}

.mat-calendar-table .mat-calendar-table-header th {
  border: 0px;
}

.mat-calendar-table .mat-calendar-table-header .mat-calendar-table-header-divider {
  padding: 0px;
}

.mat-calendar-table .mat-calendar-table-header .mat-calendar-table-header-divider:after {
  height: 0px;
}

.mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: #2574A9;
}

.mat-checkbox .mat-checkbox-layout {
  margin-bottom: 0px;
}

.mat-checkbox .mat-checkbox-label {
  font-size: 16px;
  font-weight: 400;
}

.mat-checkbox .mat-checkbox-inner-container {
  vertical-align: unset;
  top: 2px;
}

.mat-checkbox .mat-checkbox-ripple .mat-ripple-element {
  background-color: #2574A9 !important;
}

.mat-tab-group {
  margin: 0px;
}

.mat-tab-group .mat-tab-header {
  border-bottom: 3px solid #ccc;
}

.mat-tab-group .mat-tab-body {
  background-color: #ffffff;
  padding: 15px 0px;
}

.mat-tab-group .mat-tab-body-content {
  box-shadow: none;
}

.mat-tab-group .mat-tab-label {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1.6875rem;
  word-spacing: 0;
}

.mat-tab-group .mat-ink-bar {
  background-color: #2574A9 !important;
}

.mat-snack-bar-container {
  padding: 0px !important;
  min-height: unset !important;
  color: #ffffff;
}

.mat-menu-panel {
  max-width: unset !important;
  padding: 15px;
  border: 2px solid #bebebe82 !important;
}

.mat-menu-panel .mat-menu-content {
  padding: 0px;
}

.material-icons {
  color: #BFBFBF;
  font-size: 20px;
}

.icon-user {
  font-family: icomoon !important;
}

opt-regular-booking, .opt-regular-booking {
  min-height: calc(100vh - 875px);
  font-family: "Muli", sans-serif !important;
  background-color: #f5f5f5;
}

opt-regular-booking .container, .opt-regular-booking .container {
  max-width: 87vw;
}

opt-regular-booking .wp-overlay-container .cdk-overlay-backdrop, .opt-regular-booking .wp-overlay-container .cdk-overlay-backdrop {
  position: fixed;
  z-index: 10000;
}

opt-regular-booking .wp-overlay-container .cdk-global-overlay-wrapper, .opt-regular-booking .wp-overlay-container .cdk-global-overlay-wrapper {
  position: fixed;
  z-index: 10000;
}

opt-regular-booking .mat-radio-button.mat-radio-checked .mat-radio-outer-circle, .opt-regular-booking .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2574A9 !important;
}

opt-regular-booking .mat-radio-button.mat-radio-checked .mat-radio-inner-circle, .opt-regular-booking .mat-radio-button.mat-radio-checked .mat-radio-inner-circle {
  background-color: #2574A9 !important;
}

opt-regular-booking .mat-radio-button .mat-radio-ripple .mat-ripple-element, .opt-regular-booking .mat-radio-button .mat-radio-ripple .mat-ripple-element {
  background-color: #2574A9 !important;
}

opt-regular-booking .mat-radio-button .mat-radio-label-content, .opt-regular-booking .mat-radio-button .mat-radio-label-content {
  font-weight: 400;
}

opt-regular-booking .mat-radio-button .mat-radio-label, .opt-regular-booking .mat-radio-button .mat-radio-label {
  margin-bottom: 0px;
}

opt-regular-booking .mat-radio-button .mat-radio-container, .opt-regular-booking .mat-radio-button .mat-radio-container {
  top: 0px !important;
  height: 20px;
}

opt-regular-booking .mat-form-field, .opt-regular-booking .mat-form-field {
  margin-bottom: 3px;
}

opt-regular-booking .mat-form-field .mat-form-field-wrapper, .opt-regular-booking .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 2px;
}

opt-regular-booking .mat-form-field .mat-form-field-label-wrapper, .opt-regular-booking .mat-form-field .mat-form-field-label-wrapper {
  min-height: 50px;
  top: -21px;
}

opt-regular-booking .mat-form-field .mat-form-field-infix, .opt-regular-booking .mat-form-field .mat-form-field-infix {
  padding: 0px;
  border-top: none;
  font-size: 16px;
  letter-spacing: normal;
  word-spacing: 0;
}

opt-regular-booking .mat-form-field .mat-form-field-underline, .opt-regular-booking .mat-form-field .mat-form-field-underline {
  bottom: 0px;
}

opt-regular-booking .mat-form-field .mat-paginator-page-size, .opt-regular-booking .mat-form-field .mat-paginator-page-size {
  align-items: center !important;
}

opt-regular-booking .mat-form-field .mat-paginator-page-size-select, .opt-regular-booking .mat-form-field .mat-paginator-page-size-select {
  margin: unset;
  margin-bottom: 5px;
}

opt-regular-booking .mat-form-field.mat-form-field-should-float .mat-form-field-label, .opt-regular-booking .mat-form-field.mat-form-field-should-float .mat-form-field-label {
  top: 25px;
  font-weight: 500;
  color: #707070;
}

opt-regular-booking .mat-button, .opt-regular-booking .mat-button {
  font-family: "Muli", sans-serif !important;
  word-spacing: unset;
  letter-spacing: unset;
  transition: 0.3s !important;
  min-width: 100px;
}

opt-regular-booking .mat-btn-primary, .opt-regular-booking .mat-btn-primary {
  /*default*/
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-size: 16px !important;
  color: #ffffff !important;
  text-align: center !important;
  background-color: #2574A9 !important;
}

opt-regular-booking .mat-btn-primary:hover, .opt-regular-booking .mat-btn-primary:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

opt-regular-booking .mat-btn-primary--small, .opt-regular-booking .mat-btn-primary--small {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: "Muli", sans-serif;
  color: #ffffff !important;
  background-color: #2574A9 !important;
  text-align: center !important;
  font-size: 14px !important;
}

opt-regular-booking .mat-btn-primary--small:hover, .opt-regular-booking .mat-btn-primary--small:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

opt-regular-booking .mat-btn-secondary, .opt-regular-booking .mat-btn-secondary {
  /*default*/
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: "Muli", sans-serif;
  font-size: 16px !important;
  text-align: center !important;
  border: 1px solid #2574A9;
  color: #2574A9 !important;
  background-color: #ffffff;
}

opt-regular-booking .mat-btn-secondary:hover, .opt-regular-booking .mat-btn-secondary:hover {
  background-color: #2574A9;
  color: #ffffff !important;
}

opt-regular-booking .mat-btn-secondary--small, .opt-regular-booking .mat-btn-secondary--small {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #2574A9;
  color: #2574A9 !important;
  text-align: center !important;
  font-size: 14px !important;
  background-color: #ffffff;
}

opt-regular-booking .mat-btn-secondary--small:hover, .opt-regular-booking .mat-btn-secondary--small:hover {
  background-color: #2574A9 !important;
  color: #ffffff !important;
}

opt-regular-booking .mat-btn-tertiary, .opt-regular-booking .mat-btn-tertiary {
  /*default*/
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: "Muli", sans-serif;
  font-size: 16px !important;
  text-align: center !important;
  border: 1px solid #DC2A2A;
  color: #DC2A2A !important;
  background-color: #ffffff;
}

opt-regular-booking .mat-btn-tertiary:hover, .opt-regular-booking .mat-btn-tertiary:hover {
  background-color: #DC2A2A;
  color: #ffffff !important;
}

opt-regular-booking .mat-btn-tertiary--small, .opt-regular-booking .mat-btn-tertiary--small {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #DC2A2A;
  color: #DC2A2A !important;
  text-align: center !important;
  font-size: 14px !important;
  background-color: #ffffff;
}

opt-regular-booking .mat-btn-tertiary--small:hover, .opt-regular-booking .mat-btn-tertiary--small:hover {
  background-color: #DC2A2A !important;
  color: #ffffff !important;
}

opt-regular-booking .mat-btn-icon, .opt-regular-booking .mat-btn-icon {
  min-width: unset;
  padding: 0px;
  color: #BFBFBF;
  transition: 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

opt-regular-booking .mat-btn-icon .mat-button-wrapper, .opt-regular-booking .mat-btn-icon .mat-button-wrapper {
  width: 40px;
  height: 40px;
  min-width: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

opt-regular-booking .mat-btn-icon--secondary, .opt-regular-booking .mat-btn-icon--secondary {
  cursor: pointer;
  transition: 0.3s;
}

opt-regular-booking .mat-btn-icon--secondary:hover, .opt-regular-booking .mat-btn-icon--secondary:hover {
  color: #2574A9;
}

opt-regular-booking .mat-btn-icon--secondary.icon-delete:hover, .opt-regular-booking .mat-btn-icon--secondary.icon-delete:hover {
  color: #DC2A2A;
}

opt-regular-booking .mat-btn-icon.btn-delete, .opt-regular-booking .mat-btn-icon.btn-delete {
  transition: 0.3s;
}

opt-regular-booking .mat-btn-icon.btn-delete:hover i, .opt-regular-booking .mat-btn-icon.btn-delete:hover i {
  color: #DC2A2A;
}

opt-regular-booking .mat-paginator, .opt-regular-booking .mat-paginator {
  width: 100%;
}

opt-regular-booking .mat-stepper-horizontal .mat-step-header .mat-step-icon-selected, .opt-regular-booking .mat-stepper-horizontal .mat-step-header .mat-step-icon-selected {
  background-color: #2574A9;
}

opt-regular-booking .mat-stepper-horizontal .mat-step-header .mat-step-label, .opt-regular-booking .mat-stepper-horizontal .mat-step-header .mat-step-label {
  padding: 5px 0px 0px 0px;
}

opt-regular-booking .mat-stepper-horizontal .mat-step-header .mat-step-label.mat-step-label-selected, .opt-regular-booking .mat-stepper-horizontal .mat-step-header .mat-step-label.mat-step-label-selected {
  color: #2574A9;
  font-weight: 500;
}

opt-regular-booking .mat-step-icon-state-done, .opt-regular-booking .mat-step-icon-state-done {
  background-color: #66BB6A !important;
}

opt-regular-booking .mat-step-icon-state-done .material-icons, .opt-regular-booking .mat-step-icon-state-done .material-icons {
  color: #ffffff;
}

opt-regular-booking input, .opt-regular-booking input {
  caret-color: #373D3F !important;
}

.wp-loading-spinner--full {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 9999;
  position: relative;
}

.wp-loading-spinner--full .loading-spinner--body {
  background-color: #ffffff;
  padding: 35px;
  width: 500px;
}

.wp-loading-spinner--full .mat-spinner circle {
  stroke: #2574A9 !important;
  stroke-width: 5% !important;
}

.wp-show {
  display: block !important;
}

.wp-hide {
  display: none !important;
}

.wp-form-field--error .mat-form-field-underline {
  height: 2px !important;
  background-color: #DC2A2A !important;
}

.nbc .wp-rb-main-layout .message--bubble-notification {
  width: 320px;
}

.nbc .btn-sidepanel-open {
  top: 7%;
}

@media only screen and (min-width: 1400px) {
  .nbc .btn-sidepanel-open {
    top: 18%;
  }
}

.nbc .sidepanel-btn-summary {
  top: 47%;
}

.nbc .wp-txt-body2, .nbc .wp-txt-body2--small {
  font-weight: 700 !important;
}

.nbc .wp-heading-4, .nbc .wp-heading-3, .nbc .wp-heading-2 {
  font-weight: 700 !important;
}

.nbc .mat-primary .mat-pseudo-checkbox-checked:after {
  top: 3px;
  left: 1px;
}

.nbc .body-wrap {
  padding-top: 0px !important;
}

.nbc .header {
  position: relative !important;
}

.nbc .footer__back-to-top {
  cursor: pointer;
}

.nbc .header-hamburger-icon {
  color: #000000 !important;
  background: #c9da43;
  padding: 5px 10px 5px 10px !important;
  margin-right: 15px;
  border-radius: 3px;
  display: flex;
}

.nbc .header-hamburger-icon:hover {
  color: #c9da43 !important;
  background: #000000;
}

.cog .wp-txt-body2, .cog .wp-txt-body2--small {
  font-weight: 600 !important;
}

.cog .wp-heading-4, .cog .wp-heading-3, .cog .wp-heading-2 {
  font-weight: 500 !important;
}

.cog .home-inner-container {
  padding: 0px 25px !important;
}

@media screen and (max-width: 415px) {
  .header__user {
    margin-bottom: 85px;
    z-index: 999999;
  }
}

.header__user .user {
  height: 65px;
}

.header__user .user .header__alter-menu {
  align-items: center;
  display: flex;
}

.header__user .user .sub_left {
  width: 60%;
}

@media screen and (max-width: 768px) {
  .header__user .user .sub_left {
    flex: 0 0 100%;
    max-width: unset;
  }
}

@media screen and (max-width: 375px) {
  .header__user .user .sub_left {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
  }
  .header__user .user .sub_left .wp-mr-20 {
    padding-bottom: 10px;
  }
}

.header__user .user .sub_left .header-nav-venue, .header__user .user .sub_left .header-nav-rb {
  height: 25px;
  min-width: 180px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  text-decoration: unset !important;
}

.header__user .user .sub_left .header-nav-venue:hover, .header__user .user .sub_left .header-nav-rb:hover {
  background-color: #e0e0e0;
  border-radius: 12px;
}

.header__user .user .sub_left .header-nav-venue:before {
  content: 'account_balance';
  font-family: "Material Icons";
  transition: 0.3s;
  margin-right: 5px;
  background-color: #7E57C2;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__user .user .sub_left .header-nav-venue:hover:before {
  background-color: #fff;
  color: #7E57C2;
}

.header__user .user .sub_left .header-nav-rb:before {
  content: 'assignment';
  font-family: "Material Icons";
  transition: 0.3s;
  font-size: 15px;
  margin-right: 5px;
  background-color: #7E57C2;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__user .user .sub_left .header-nav-rb:hover:before {
  background-color: #fff;
  color: #7E57C2;
}

.header__user .user .sub {
  width: 40% !important;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .header__user .user .sub {
    width: 100% !important;
  }
}

@media print {
  .header__user .user .sub {
    width: auto !important;
    display: flex !important;
    justify-content: flex-end !important;
  }
}

.header__user .user .sub .user__left {
  flex-grow: inherit !important;
}

.public-web .checkout-page .checkout-stepper .delivery-method-details .mat-checkbox .mat-checkbox-inner-container {
  top: 5px !important;
}

.user-menue-wrapper.ng-star-inserted {
  display: flex !important;
  align-items: center !important;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
body {
  color: #000000;
  font-family: "Muli", sans-serif !important;
  font-size: 16px;
}

.wp-txt--primary {
  color: #373D3F !important;
}

.wp-txt--secondary {
  color: #707070 !important;
}

.wp-txt--tertiary {
  color: #2574A9 !important;
}

.wp-txt--quaternary {
  color: #AA5D00 !important;
}

.wp-txt--danger {
  color: #DC2A2A !important;
}

.wp-txt--white {
  color: #ffffff !important;
}

.wp-primary-icon {
  color: #BFBFBF;
  padding: 0 5px;
}

.wp-section-title {
  margin-bottom: 20px;
}

.wp-txt-title {
  font-size: 18px !important;
  color: #373D3F;
}

.wp-txt-subtitle {
  font-size: 16px !important;
  color: #707070;
}

.wp-txt--underline {
  text-decoration: underline;
}

.wp-txt--line-through {
  text-decoration: line-through;
}

.wp-txt--line-overline {
  text-decoration: overline;
  font-size: 12px;
  line-height: 16px;
}

.wp-txt-align--right {
  text-align: right !important;
}

.wp-txt-align--left {
  text-align: left !important;
}

.wp-txt-align--center {
  text-align: center !important;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h1.wp__heading-1 small, h2.wp__heading-2 small, h3.wp__heading-3 small, h4.wp__heading-4 small, h5.wp__heading-5 small, h6.wp__heading-6 small {
  font-family: "Muli", sans-serif;
  font-size: 32px;
  font-weight: 500;
  /* line-height: 1.35em;*/
  line-height: 36px;
  font-size: 0.6em;
}

h1.wp-heading-1, .wp-txt-body2, .wp-txt-body2--xsmall, .wp-txt-body2--small, .wp-txt-body2--large, .wp-txt-body1, .wp-txt-body1--xsmall, .wp-txt-body1--small, .wp-txt-body1--large {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h1.wp-heading-1 {
  font-family: "Muli", sans-serif;
  font-size: 32px;
  font-weight: 500;
  /* line-height: 1.35em;*/
  line-height: 36px;
  margin-top: 0;
  margin-bottom: 0;
}

h2.wp-heading-2 {
  font-family: "Muli", sans-serif;
  font-size: 24px;
  font-weight: 500;
  /* line-height:0 1.1em;*/
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

h3.wp-heading-3 {
  font-family: "Muli", sans-serif;
  font-size: 20px;
  font-weight: 500;
  /*line-height: 1em;*/
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

h4.wp-heading-4 {
  font-family: "Muli", sans-serif;
  font-size: 20px;
  font-weight: 500;
  /*line-height: 1em;*/
  line-height: 20px;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

.wp-subtitle-1 {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: 400;
  /*  line-height: 32px;*/
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 0;
}

.wp-subtitle-1-small {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: 400;
  /*  line-height: 32px;*/
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  line-height: 12px;
}

.wp-subtitle-1-large {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: 400;
  /*  line-height: 32px;*/
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 16px;
}

.wp-subtitle-2 {
  font-family: "Muli", sans-serif;
  font-size: 20px;
  font-weight: 500;
  /* line-height: 1;
  letter-spacing: 0.02em;*/
  font-size: 16px;
  line-height: 16px;
}

.wp-subtitle-2-small {
  font-family: "Muli", sans-serif;
  font-size: 20px;
  font-weight: 500;
  /* line-height: 1;
  letter-spacing: 0.02em;*/
  font-size: 14px;
  line-height: 14px;
}

.wp-subtitle-2-large {
  font-family: "Muli", sans-serif;
  font-size: 20px;
  font-weight: 500;
  /* line-height: 1;
  letter-spacing: 0.02em;*/
  font-size: 18px;
  line-height: 18px;
}

.wp-txt--caption {
  font-size: 14px;
  font-weight: 400;
  /* line-height: 1;*/
  line-height: 12px;
  letter-spacing: 0;
  font-style: normal;
  color: #909cac;
}

.wp-txt--link {
  color: #2574A9 !important;
  text-decoration: none;
  cursor: pointer;
}

.wp-txt--link:hover {
  text-decoration: underline !important;
}

.wp-txt-body2 {
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 400 !important;
  /* line-height: 20px;*/
  line-height: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  white-space: normal;
}

@media (min-width: 300px) {
  .wp-txt-body2 {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 768px) {
  .wp-txt-body2 {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 1024px) {
  .wp-txt-body2 {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 1200px) {
  .wp-txt-body2 {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

.wp-txt-body2--xsmall {
  font-size: 12px !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 400 !important;
  /* line-height: 20px;*/
  line-height: 16px;
  letter-spacing: 0;
}

@media (min-width: 300px) {
  .wp-txt-body2--xsmall {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 768px) {
  .wp-txt-body2--xsmall {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 1024px) {
  .wp-txt-body2--xsmall {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 1200px) {
  .wp-txt-body2--xsmall {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

.wp-txt-body2--small {
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 400 !important;
  /* line-height: 20px;*/
  line-height: 16px;
  letter-spacing: 0;
  font-size: 14px !important;
}

@media (min-width: 300px) {
  .wp-txt-body2--small {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 768px) {
  .wp-txt-body2--small {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 1024px) {
  .wp-txt-body2--small {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 1200px) {
  .wp-txt-body2--small {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

.wp-txt-body2--large {
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 500 !important;
  font-size: 16px;
  font-weight: 400 !important;
  /* line-height: 20px;*/
  line-height: 16px;
  letter-spacing: 0;
  font-size: 18px;
}

@media (min-width: 300px) {
  .wp-txt-body2--large {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 768px) {
  .wp-txt-body2--large {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 1024px) {
  .wp-txt-body2--large {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

@media (min-width: 1200px) {
  .wp-txt-body2--large {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

.wp-txt-body1 {
  font-size: 16px;
  font-weight: 400 !important;
  font-size: 16px;
  font-weight: 400 !important;
  font-size: 16px;
  font-weight: 400 !important;
  font-size: 16px;
  font-weight: 400 !important;
  /*line-height: 18px;*/
  line-height: 1.5;
  letter-spacing: 0;
  white-space: normal;
}

@media (min-width: 300px) {
  .wp-txt-body1 {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

@media (min-width: 768px) {
  .wp-txt-body1 {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

@media (min-width: 1024px) {
  .wp-txt-body1 {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

@media (min-width: 1200px) {
  .wp-txt-body1 {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

.wp-txt-body1--xsmall {
  font-size: 12px;
  white-space: normal;
}

.wp-txt-body1--small {
  font-size: 16px;
  font-weight: 400 !important;
  font-size: 16px;
  font-weight: 400 !important;
  font-size: 16px;
  font-weight: 400 !important;
  font-size: 16px;
  font-weight: 400 !important;
  /*line-height: 18px;*/
  line-height: 1.5;
  letter-spacing: 0;
  white-space: normal;
  font-size: 14px !important;
}

@media (min-width: 300px) {
  .wp-txt-body1--small {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

@media (min-width: 768px) {
  .wp-txt-body1--small {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

@media (min-width: 1024px) {
  .wp-txt-body1--small {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

@media (min-width: 1200px) {
  .wp-txt-body1--small {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

.wp-txt-body1--large {
  font-size: 18px;
}

.wp-perfect-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-mb-5 {
  margin-bottom: 5px;
}

.wp-mb-10 {
  margin-bottom: 10px;
}

.wp-mb-15 {
  margin-bottom: 15px;
}

.wp-mb-20 {
  margin-bottom: 20px !important;
}

.wp-mb-30 {
  margin-bottom: 30px !important;
}

.wp-mr-5 {
  margin-right: 5px;
}

.wp-mr-10 {
  margin-right: 10px !important;
}

.wp-mr-20 {
  margin-right: 20px;
}

.wp-mt-0 {
  margin-top: 0px !important;
}

.wp-mt-10 {
  margin-top: 10px !important;
}

.wp-mt-20 {
  margin-top: 20px;
}

.wp-mt-30 {
  margin-top: 30px;
}

.wp-ml-10 {
  margin-left: 10px;
}

.wp-pr-10 {
  padding-right: 10px;
}

.wp-pr-15 {
  padding-right: 15px;
}

.wp-pr-30 {
  padding-right: 30px;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.recurring-booking-popup .recurring-pattern-wrapper {
  background-color: #f5f5f5;
  padding: 15px;
}

.recurring-booking-popup .recurring-pattern-wrapper .recurring-pattern-header {
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
}

.recurring-booking-popup .recurring-pattern-wrapper .recurring-pattern-header .mat-radio-label {
  margin-bottom: 0px;
}

.recurring-booking-popup .mat-radio-label {
  width: 83% !important;
}

.recurring-booking-popup .range-wrapper .mat-radio-label {
  width: 100% !important;
}

.recurring-booking-popup .weekly-inner .mat-radio-label {
  width: 100% !important;
}

.recurring-booking-popup .daily-inner .mat-radio-label {
  width: 100% !important;
}

.recurring-booking-popup .mat-radio-label-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.recurring-booking-popup .daily-inner {
  background-color: #ffffff;
  padding: 15px;
}

.recurring-booking-popup .daily-inner .mat-radio-group {
  display: flex;
  flex-direction: column;
}

.recurring-booking-popup .daily-inner .everyday-input {
  max-width: 50px;
  margin: 0 10px;
}

.recurring-booking-popup .weekly-inner {
  background-color: #ffffff;
  padding: 15px;
}

.recurring-booking-popup .weekly-inner .mat-checkbox {
  margin-right: 40px;
}

.recurring-booking-popup .weekly-inner .every-week-input {
  max-width: 50px;
  margin: 0 10px;
}

.recurring-booking-popup .weekly-inner .checkbox-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.recurring-booking-popup .weekly-inner .checkbox-section .mat-checkbox {
  width: 100px;
}

.recurring-booking-popup .monthly-inner {
  background-color: #ffffff;
  padding: 15px;
}

.recurring-booking-popup .monthly-inner .mat-form-field {
  margin: 0 8px;
  width: 20% !important;
  flex: 0 0 20%;
  display: flex !important;
  justify-content: space-between;
}

.recurring-booking-popup .monthly-inner .day-of-month-input {
  max-width: 50px;
}

.recurring-booking-popup .monthly-inner .monthly-day-field {
  width: 100px;
}

@media (max-width: 690px) {
  .recurring-booking-popup .overflowScrollSec {
    overflow-y: scroll;
  }
}

.recurring-booking-popup .range-wrapper {
  background-color: #f5f5f5;
  padding: 15px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.recurring-booking-popup .range-wrapper .mat-form-field {
  max-width: 150px;
  margin: 0 5px;
}

.recurring-booking-popup .range-wrapper .range-body {
  background-color: #ffffff;
  padding: 15px;
  flex-grow: 1;
}

.recurring-booking-popup .range-wrapper .range-body .mat-radio-group .mat-radio-button {
  margin-bottom: 10px;
}

.recurring-booking-popup .range-wrapper .range-body .mat-radio-group .mat-radio-button:nth-last-child(1) {
  margin-bottom: 0px;
}

.recurring-booking-popup .range-wrapper .occurence-input {
  max-width: 50px;
  margin: 0 10px;
}

.recurring-booking-popup .recurring-left-col-wrapper {
  max-height: 435px;
  display: flex;
  flex-direction: column;
}

.recurring-booking-popup .recurring-right-col-wrapper {
  background-color: #f5f5f5;
  padding: 15px;
  height: 100%;
  overflow-y: auto;
}

.recurring-booking-popup .view-dates-container {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.recurring-booking-popup .mat-tab-body .mat-tab-body-content {
  box-shadow: unset;
  overflow: hidden;
}

.recurring-booking-popup .mat-tab-body .dates-selected-container {
  background-color: #f5f5f5;
}

.recurring-booking-popup .mat-tab-body .dates-selected-container .selected-date-row {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 5px;
  /*i{
                    color:$wp-icon-primary;
                    font-size:20px;
                    cursor:pointer;
                }*/
}

.recurring-booking-popup .mat-tab-body .mat-paginator {
  width: 100%;
  background-color: #ffffff;
}

.recurring-booking-popup .mat-form-field-wrapper {
  width: 100%;
}

.recurring-booking-popup .range-body .mat-radio-label-content {
  justify-content: unset !important;
}

.mat-dialog-container .mat-dialog-content .modal-header button.mat-button:focus {
  border: unset !important;
}

.w90modal {
  width: 90% !important;
  max-width: 90% !important;
}

.wp-modal-header {
  display: flex;
  align-items: center;
  height: 55px;
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  position: relative;
  z-index: 999;
}

.wp-modal-header button {
  background-color: transparent;
  box-shadow: none;
  border: none;
  display: flex;
  align-items: center;
}

.wp-modal-body {
  background-color: #ffffff;
  padding: 25px 15px;
  max-height: 80vh;
  overflow-y: scroll;
}

.wm-facility-config .mat-select {
  width: 50% !important;
}

@media screen and (min-width: 1024px) {
  ::ng-deep .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination.mat-tab-header-pagination {
    display: none !important;
  }
}

.wp-modal-footer {
  background: #f5f5f5;
  padding: 15px;
  position: sticky;
}

.wp-modal-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  position: relative;
  padding: 0 15px;
  z-index: 999;
  height: 55px;
  background-color: #ffffff;
}

.wp-pricing-modal .wp-modal-body {
  background-color: #f5f5f5;
  padding: 15px;
}

.wp-pricing-modal .pricing-container {
  background-color: #f5f5f5;
  padding: 0px;
}

.wp-pricing-modal .pricing-container > .wp_flex--container:nth-last-child(1) {
  margin-bottom: 0px !important;
}

.wp-pricing-modal .pricing-container .pricing-row-container {
  padding: 15px;
  background-color: #ffffff;
  /*margin-bottom:10px;*/
}

.wp-pricing-modal .pricing-container .pricing-row-container > .wp_flex__item--inherit {
  flex-grow: 1;
  max-width: 300px;
}

.wp-pricing-modal .pricing-container .pricing-row-container > .wp_flex__item--inherit:nth-child(1) {
  flex-grow: inherit !important;
}

.wp-adhoc-date-selection .adhoc-left-col-wrapper {
  background-color: #f5f5f5;
  padding: 15px;
}

.wp-adhoc-date-selection .adhoc-right-col-wrapper {
  background-color: #f5f5f5;
  padding: 15px;
  height: 100%;
}

.wp-adhoc-date-selection .mat-calendar {
  width: 300px !important;
  background-color: #ffffff;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
}

.wp-adhoc-date-selection .mat-calendar th {
  text-align: center;
}

.wp-adhoc-date-selection .mat-calendar-table-header-divider {
  border-bottom: 0px;
}

.wp-adhoc-date-selection .dates-selected-container .selected-date-row {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 5px;
}

.wp-adhoc-date-selection .dates-selected-container .selected-date-row i {
  color: #BFBFBF;
  font-size: 20px;
  cursor: pointer;
}

.wp-adhoc-date-selection .dates-selected-container .mat-paginator {
  width: 100%;
  background-color: #ffffff;
}

.wp-adhoc-date-selection .legend-container {
  justify-content: space-between;
  margin-bottom: 15px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.wp-adhoc-date-selection .selected-date-legend {
  background-color: #2E7D32;
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.wp-adhoc-date-selection .unavailable-date-legend {
  background-color: #DC2A2A;
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.wp-adhoc-date-selection .special-date-legend {
  background-color: #BFBFBF;
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.wp-adhoc-date-selection .adhoc-help-text-container {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media screen and (max-width: 1200px) {
  .wp-booking-details-modal .wp-txt-body1 {
    font-size: 14px !important;
  }
  .wp-booking-details-modal .wp-txt-body2 {
    font-size: 14px !important;
  }
}

.wp-booking-details-modal .facility-panel .facility-panel-row {
  margin: 0px;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .wp-booking-details-modal .facility-panel .facility-panel-row .wp-txt-body1 {
    font-size: 14px !important;
  }
  .wp-booking-details-modal .facility-panel .facility-panel-row .wp-txt-body2 {
    font-size: 14px !important;
  }
}

.wp-booking-details-modal .booking-details-header-title-container {
  overflow: hidden;
}

.wp-booking-details-modal .booking-details-header-title {
  white-space: nowrap;
}

.wp-booking-details-modal .mat-expansion-panel-header.mat-expanded .booking-details-header-title-container {
  overflow: visible;
}

.wp-booking-details-modal .mat-expansion-panel-header.mat-expanded .booking-details-header-title {
  white-space: normal;
}

.wp-booking-details-modal .facility-timeslot-row {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 5px;
  border-left: 5px solid #66BB6A;
}

.wp-booking-details-modal .facility-timeslot-row.conflict-row {
  color: #DC2A2A !important;
  border-left: 5px solid #DC2A2A !important;
}

.wp-booking-details-modal .facility-timeslot-row .alternate-options-row {
  background-color: #f5f5f5;
  padding: 5px;
  margin-top: 15px;
}

.wp-booking-details-modal .alternate-options-container {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
}

.wp-booking-details-modal .alternate-options-container .mat-radio-group {
  display: flex;
  flex-direction: column;
}

.wp-booking-details-modal .add-extras-container {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.wp-booking-details-modal .add-extras-container .extras-row-wrapper {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
}

.wp-booking-details-modal .add-extras-container .extras-row {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 5px;
}

.wp-booking-details-modal .add-extras-container .extras-row:nth-last-child(1) {
  margin-bottom: 0px;
}

.wp-booking-details-modal .wp-txt--danger {
  color: #DC2A2A !important;
}

.wp-booking-details-modal .rb-date-time-wrap {
  display: flex;
  width: 100%;
}

.wp-booking-details-modal .rb-facility-name {
  width: 40%;
}

@media screen and (max-width: 1227px) and (min-width: 1200px) {
  .wp-booking-details-modal .rb-facility-name {
    width: 41% !important;
  }
}

@media screen and (max-width: 1200px) {
  .wp-booking-details-modal .rb-facility-name {
    width: 50% !important;
  }
  .wp-booking-details-modal .rb-facility-name .wp-txt-body1 {
    font-size: 14px !important;
  }
}

.wp-booking-details-modal .rb-facility-config {
  width: 15%;
  padding-left: 10px;
}

.wp-booking-details-modal .rb-facility-availability {
  width: 18%;
}

@media screen and (max-width: 1262px) and (min-width: 1200px) {
  .wp-booking-details-modal .rb-facility-availability {
    width: 19% !important;
  }
}

@media screen and (max-width: 1200px) {
  .wp-booking-details-modal .rb-facility-availability {
    width: 20% !important;
  }
}

.wp-booking-details-modal .rb-facility-recurring {
  width: 10%;
}

@media screen and (max-width: 1200px) {
  .wp-booking-details-modal .rb-facility-recurring {
    width: 8% !important;
  }
}

.wp-booking-details-modal .rb-facility-delete {
  width: 5%;
}

.wp-booking-details-modal .rb-time-range {
  flex-direction: row !important;
  align-items: center;
  color: #797070;
}

.wp-booking-details-modal .rb-time-range::before {
  content: '|';
  padding: 0 10px 0 10px;
}

.mat-horizontal-stepper-content .rb-facility-name {
  width: 50%;
}

@media screen and (max-width: 1227px) and (min-width: 1200px) {
  .mat-horizontal-stepper-content .rb-facility-name {
    width: 50% !important;
  }
}

@media screen and (max-width: 1200px) {
  .mat-horizontal-stepper-content .rb-facility-name {
    width: 50% !important;
  }
  .mat-horizontal-stepper-content .rb-facility-name .wp-txt-body1 {
    font-size: 14px !important;
  }
}

.mat-horizontal-stepper-content .rb-facility-config {
  width: 15%;
  padding-left: 10px;
}

@media screen and (max-width: 1227px) and (min-width: 1200px) {
  .mat-horizontal-stepper-content .rb-facility-config {
    width: 16% !important;
  }
}

@media screen and (max-width: 1200px) {
  .mat-horizontal-stepper-content .rb-facility-config {
    padding-left: 5px;
  }
}

.mat-horizontal-stepper-content .rb-facility-availability {
  width: 20%;
}

@media screen and (max-width: 1262px) and (min-width: 1200px) {
  .mat-horizontal-stepper-content .rb-facility-availability {
    width: 21% !important;
  }
}

@media screen and (max-width: 1200px) {
  .mat-horizontal-stepper-content .rb-facility-availability {
    width: 23% !important;
  }
}

.mat-horizontal-stepper-content .rb-facility-recurring {
  width: 10%;
}

@media screen and (max-width: 1262px) and (min-width: 1200px) {
  .mat-horizontal-stepper-content .rb-facility-recurring {
    width: 9% !important;
  }
}

@media screen and (max-width: 1200px) {
  .mat-horizontal-stepper-content .rb-facility-recurring {
    width: 8% !important;
  }
}

.mat-horizontal-stepper-content .rb-facility-delete {
  width: 1%;
}

.wp-booking-details-pattern-modal .mat-tab-group .mat-tab-label {
  display: none;
}

.wp-booking-details-pattern-modal .mat-tab-group .mat-tab-header {
  border-bottom: 0px;
}

.wp-booking-details-pattern-modal .mat-tab-group .mat-tab-header .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

.wp-resolve-conflicts-modal .available-timeslots-container {
  background-color: #f5f5f5;
  padding: 15px;
}

.wp-resolve-conflicts-modal .available-timeslots-container .chip-container {
  flex-wrap: wrap;
}

.wp-resolve-conflicts-modal .available-timeslots-container .chip-container > .wp_flex__item--inherit {
  width: calc(96% / 3);
  margin-right: 5px;
  margin-bottom: 5px;
}

.wp-resolve-conflicts-modal .available-timeslots-container .chip-container .chip-select {
  padding: 10px 15px;
  color: #2E7D32;
  cursor: pointer;
  background-color: #ffffff;
  transition: 0.3s;
  border-radius: 10px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
}

.wp-resolve-conflicts-modal .available-timeslots-container .chip-container .chip-select:hover {
  background-color: #2E7D32;
  color: #ffffff;
}

.wp-resolve-conflicts-modal .available-timeslots-container .chip-container .chip-select.selected {
  background-color: #2E7D32;
  color: #ffffff;
  padding: unset !important;
}

.wp-resolve-conflicts-modal .available-timeslots-container .chip-container .chip-select.selected span {
  padding: 15px 0 !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.wp-resolve-conflicts-modal .adhoc-left-col-wrapper {
  margin-bottom: 10px;
  margin-bottom: 0px;
}

@media (min-width: 300px) {
  .wp-resolve-conflicts-modal .adhoc-left-col-wrapper {
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .wp-resolve-conflicts-modal .adhoc-left-col-wrapper {
    margin-bottom: 0px;
  }
}

.wp-resolve-conflicts-modal .timepicker-wrapper {
  margin-bottom: 20px;
}

.wp-resolve-conflicts-modal .timepicker-container {
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 20px;
  background-color: white;
  margin-right: 20px;
  height: 150px;
}

.wp-rollover-booking-modal .wp-txt-title {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.rollover-items {
  display: block !important;
}

.item-count {
  margin-left: 24px;
  font-size: 13px !important;
}

.search-pagi .mat-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select {
  width: 56px !important;
}

.all-popups button {
  margin: 15px;
}

.notes-popup {
  width: 500px !important;
}

.notes-popup h4 {
  margin-bottom: 10px;
}

.notes-popup .notes-container {
  padding: 15px;
  border: 1px solid #bfbfbf;
}

.notes-popup .notes-team-title {
  margin-bottom: 5px;
}

.standard-items-popup {
  width: 500px !important;
  min-width: 500px;
}

.standard-items-popup h4 {
  margin-bottom: 10px;
}

.standard-items-popup .standard-items-container {
  padding: 15px;
  border: 1px solid #bfbfbf;
}

.standard-items-popup .standard-items-team-title {
  margin-bottom: 5px;
}

.dates-popup {
  width: 400px !important;
}

.dates-popup .wp_flex__item {
  max-width: 170px;
}

.dates-popup .year-container {
  margin-bottom: 10px;
  margin-left: 10px;
}

.dates-popup .month-container {
  margin-bottom: 10px;
  margin-left: 20px;
}

.dates-popup .day-container {
  margin-bottom: 10px;
  margin-left: 30px;
}

.range-popup {
  padding: 20px;
}

.range-popup .mat-menu-content {
  padding-top: 8px !important;
  padding-bottom: 0px !important;
}

.time-range-popup {
  padding: 20px;
  min-width: 300px !important;
}

.time-range-popup .mat-form-field {
  width: 100%;
}

.time-range-popup .mat-menu-content {
  padding-top: 8px !important;
  padding-bottom: 0px !important;
}

.time-range-menu table {
  margin: 0px;
}

.time-range-menu table tbody {
  border: none !important;
}

.time-range-menu timepicker button.btn {
  background-color: #2574A9 !important;
}

.wp-carousel .ngucarousel {
  overflow: visible !important;
}

.wp-carousel .ngucarousel .ngucarousel-items {
  flex-wrap: wrap;
  margin: 0 -10px;
}

.wp-carousel .ngucarousel .item {
  flex-grow: inherit !important;
  max-width: 500px;
  display: flex;
  margin-bottom: 10px;
  margin-bottom: 0px;
}

@media (min-width: 300px) {
  .wp-carousel .ngucarousel .item {
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .wp-carousel .ngucarousel .item {
    margin-bottom: 0px;
  }
}

.wp-carousel .wp-seasons-card-wrapper {
  display: flex;
  width: 100%;
}

.wp-carousel .wp-seasons-card-wrapper .wp-seasons-card {
  width: 100%;
  min-height: 161px;
  max-width: 410px;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  padding: 15px;
  margin: 0px 10px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  transition: 0.3s;
}

.wp-carousel .wp-seasons-card-wrapper .wp-seasons-card:hover {
  transform: scale(1.05);
  position: relative;
  z-index: 999;
}

.wp-carousel .wp-seasons-card-wrapper .card-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #2574A9;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.wp-carousel .wp-seasons-card-wrapper .card-selection i {
  display: none;
}

.wp-carousel .wp-seasons-card-wrapper .card-selection.selected {
  background-color: #2574A9;
}

.wp-carousel .wp-seasons-card-wrapper .card-selection.selected i {
  display: block !important;
  color: #fff !important;
  font-size: 16px !important;
}

.wp-carousel .right-nav-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #2574A9;
  background-color: #ffffff;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  right: -24px;
  top: 65px;
  z-index: 998;
}

.wp-carousel .left-nav-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #2574A9;
  background-color: #ffffff;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  left: -24px;
  top: 65px;
  z-index: 998;
}

.wp-carousel .myPoint {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.wp-carousel .myPoint li {
  color: #bfbfbf;
  width: 20px;
}

.wp-carousel .myPoint li.active {
  color: #2574A9;
}

.opt-regular-booking .mat-horizontal-content-container {
  overflow: hidden !important;
}

.opt-regular-booking .wp-rollover-booking-modal .term-card {
  overflow: hidden;
  padding: 15px;
}

.opt-regular-booking .ngucarousel .ngucarousel-items {
  flex-wrap: nowrap !important;
}

.wp-timeslot-calendar .timeslot-container {
  background-color: #ffffff;
}

.wp-timeslot-calendar .select-team-dropdown {
  padding: 2px;
  text-align: center;
  min-width: 400px;
  max-width: 450px;
  margin-top: 10px;
  margin-top: 0px;
}

@media (min-width: 300px) {
  .wp-timeslot-calendar .select-team-dropdown {
    margin-top: 10px;
  }
}

@media (min-width: 1024px) {
  .wp-timeslot-calendar .select-team-dropdown {
    margin-top: 0px;
  }
}

.wp-timeslot-calendar .select-team-dropdown .mat-form-field {
  width: 100%;
  padding: 0px;
  border-radius: 5px;
  transition: 0.3s;
  border-bottom: 0.1px solid #e8e8e8;
}

.wp-timeslot-calendar .select-team-dropdown .mat-form-field .mat-select-value-text {
  color: #2574A9;
  font-size: 18px;
  font-weight: 400;
}

.wp-timeslot-calendar .select-team-dropdown .mat-form-field:hover .mat-form-field-underline {
  display: block;
  height: 3px;
  transition: 0.3s;
  background-color: #2574A9 !important;
}

.wp-timeslot-calendar .select-team-dropdown .mat-form-field .mat-form-field-flex {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.wp-timeslot-calendar .select-team-dropdown .mat-form-field .mat-form-field-flex .mat-form-field-infix {
  padding: 0 10px;
}

.wp-timeslot-calendar .select-team-dropdown .mat-form-field .mat-form-field-underline {
  height: 0px;
  background-color: #2574A9 !important;
}

.wp-timeslot-calendar .weekview-header-label {
  padding-right: 65px;
  margin-bottom: 10px;
  margin-bottom: 0px;
}

@media (min-width: 300px) {
  .wp-timeslot-calendar .weekview-header-label {
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .wp-timeslot-calendar .weekview-header-label {
    margin-bottom: 0px;
  }
}

.wp-timeslot-calendar .weekview-header {
  margin-bottom: 0px;
}

.wp-timeslot-calendar .hours-header .hour-cell {
  vertical-align: middle;
}

.wp-timeslot-calendar .day-header .day-cell {
  transition: 0.3s;
}

.wp-timeslot-calendar .day-header .day-cell:hover {
  cursor: pointer;
  background-color: #2574A9;
  color: #ffffff;
}

.wp-timeslot-calendar .day-header .selected-date {
  background-color: #2574A9;
  color: #ffffff;
}

.wp-timeslot-calendar .venue-container {
  background-color: #f5f5f5;
  padding: 10px !important;
}

.wp-timeslot-calendar .calendar-cell {
  width: 20px;
}

.wp-timeslot-calendar .calendar-cell.hour-cell {
  width: 80px;
}

.wp-timeslot-calendar .calendar-hour-column {
  padding: 0px !important;
}

.wp-timeslot-calendar .calendar-hour-column .wp-txt--link {
  font-size: 12px;
}

.wp-timeslot-calendar .opt-selected {
  background-color: #66BB6A;
  position: relative;
  /*&:nth-last-child(1){
            &::after {
              font-family: 'Material Icons';
              content: "delete";
              -webkit-font-feature-settings: 'liga';
            }
        }*/
}

.wp-document-upload {
  background-color: #f5f5f5;
  padding: 15px;
}

.wp-document-upload .mat-button {
  background-color: #ffffff;
}

.wp-document-upload .document-upload-container {
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  margin-top: 20px;
  margin-bottom: 30px;
}

.wp-document-upload .document-upload-container .mat-form-field {
  width: 300px;
}

.wp-document-upload .upload-panel {
  border: 1px dotted #9B59B6;
  padding: 15px;
}

.wp-document-upload .upload-panel .drop-zone {
  border: 1px dotted #2574A9 !important;
}

.wp-document-upload .upload-panel .drop-zone div:nth-child(3) {
  display: none !important;
}

.wp-document-upload .upload-icon {
  background: #BFBFBF;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.wp-document-upload .upload-icon i {
  color: #ffffff;
}

.wp-document-upload .uploaded-documents-wrapper .uploaded-documents-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.wp-document-upload .uploaded-documents-wrapper .uploaded-documents-row .left-col {
  padding: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  width: calc(100% - 60px);
  border-radius: 10px;
  background-color: #ffffff;
}

.wp-document-upload .uploaded-documents-wrapper .uploaded-documents-row .left-col .mat-form-field {
  width: 70%;
}

.mat-snack-bar-container {
  min-height: unset;
}

.wp-timeslot-message .success-message {
  background-color: #2E7D32;
  padding: 10px;
  border-radius: 5px;
}

.message--success {
  background-color: #ffffff;
  color: #2E7D32 !important;
  padding: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  margin: 15px 0px;
  border-radius: 10px;
}

.message--success .message-icon {
  width: 45px;
  height: 45px;
  border: 3px solid #2E7D32;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.message--success .message-icon i {
  font-size: 25px;
  color: #2E7D32;
}

.message--error {
  background-color: #ffffff;
  color: #DC2A2A !important;
  padding: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  margin: 15px 0px;
  border-radius: 10px;
}

.message--error .message-icon {
  width: 45px;
  height: 45px;
  border: 3px solid #DC2A2A;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.message--error .message-icon span {
  font-size: 25px;
  color: #DC2A2A;
  font-weight: 600;
}

.message--info {
  background-color: #ffffff;
  color: #7E57C2 !important;
  padding: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  margin: 15px 0px;
  border-radius: 10px;
}

.message--info .message-icon {
  width: 45px;
  height: 45px;
  border: 3px solid #7E57C2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.message--info .message-icon span {
  font-size: 25px;
  color: #7E57C2;
  font-weight: 600;
}

.message--info .message-help-image {
  margin-right: -10px;
}

.message--fill-mandatory {
  background-color: #ffffff;
  color: #DC2A2A !important;
  padding: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  margin: 15px 0px;
  border-radius: 10px;
}

.message--fill-mandatory .message-icon {
  width: 45px;
  height: 45px;
  border: 3px solid #DC2A2A;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.message--fill-mandatory .message-icon span {
  font-size: 25px;
  color: #DC2A2A;
  font-weight: 600;
}

.message--bubble-notification {
  background-color: #9B59B6;
  color: #ffffff !important;
  padding: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  max-width: 300px;
  border-radius: 10px;
  position: relative;
}

.message--bubble-notification::after {
  content: '';
  position: absolute;
  right: -10px;
  top: 17px;
  width: 0px;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid #9B59B6;
  border-bottom: 10px solid transparent;
}

.message--confirmation .confirmation-message-inner-container {
  padding: 15px;
}

.message-snackbarfull {
  width: 100vw !important;
}

.message--selected-time {
  position: fixed;
  right: 6%;
  bottom: 5%;
  background-color: #9B59B6;
  color: #ffffff !important;
  padding: 10px;
  border-radius: 5px;
  z-index: 999;
}

.wp-diary-selection-view .wp-table {
  display: block;
  width: 100%;
}

.wp-diary-selection-view .wp-table-row {
  display: flex;
  position: relative;
  min-height: 50px;
  width: fit-content;
  transform-style: preserve-3d;
}

.wp-diary-selection-view .wp-table-row:first-child {
  position: sticky;
  top: 0px;
  z-index: 998;
  background: #f5f5f5;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
}

.wp-diary-selection-view .wp-table-cell, .wp-diary-selection-view .wp-table-head {
  border: 1px solid #e0e0e0;
  display: table-cell;
  padding: 3px 10px;
}

.wp-diary-selection-view .wp-table-foot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}

.wp-diary-selection-view .wp-table-body {
  display: block;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
  position: relative;
}

.wp-diary-selection-view .resource-column-cell {
  min-height: 50px;
  width: 250px;
  min-width: 250px;
  padding: 10px;
  position: sticky;
  left: 0px;
  background-color: #fff;
  z-index: 99;
  display: flex;
  align-items: center;
}

.wp-diary-selection-view .resource-column-cell i {
  display: none;
}

.wp-diary-selection-view .resource-column-cell.venue-cell {
  font-weight: bold;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.wp-diary-selection-view .resource-column-cell.venue-cell:hover {
  cursor: pointer;
  background-color: #e0e0e0;
}

.wp-diary-selection-view .resource-column-cell.venue-cell i {
  display: block !important;
  color: #2574A9;
  background-color: #ffffff;
  border-radius: 5px;
}

.wp-diary-selection-view .resource-column-cell.venue-cell .resource-label {
  padding-left: 0px !important;
}

.wp-diary-selection-view .resource-column-cell.venue-cell .price-label {
  padding-left: 0px !important;
}

.wp-diary-selection-view .resource-column-cell .price-label {
  font-size: 12px;
  padding-left: 10px;
}

.wp-diary-selection-view .resource-column-cell .resource-label {
  white-space: normal;
  padding-left: 10px;
}

.wp-diary-selection-view .timeslot-cell {
  width: 25px;
  min-width: 25px;
  padding: 0px;
  background-color: #fff;
  padding-bottom: 45px;
}

.wp-diary-selection-view .timeslot-cell:hover {
  cursor: pointer;
  border: 2px solid #66BB6A;
}

.wp-diary-selection-view .timeslot-cell.opt-not-selectable:hover {
  cursor: not-allowed;
  border: 0px;
  border-bottom: 1px solid #e0e0e0 !important;
}

.wp-diary-selection-view .calendar-event-wrap {
  transform: translateZ(-5px);
  transform-style: preserve-3d;
}

.wp-diary-selection-view .hour-cell {
  width: 100px;
  min-width: 100px;
  padding: 0px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-diary-selection-view .selected-cell {
  background-color: #66BB6A;
  height: 100%;
  min-width: 25px;
  width: 25px;
}

.wp-diary-selection-view .selected-slots-bar {
  height: 25px;
  position: absolute;
  top: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
  display: flex;
  align-items: center;
  transform: translateZ(5px);
  /*&::after {
                  font-family: 'Material Icons';
                  content: "delete";
                  -webkit-font-feature-settings: 'liga';
                  position: absolute;
                  right: 0;
                  top:0px;
                  color: $wp-txt-primary;
                  height: 22px;
                  cursor: pointer;
                  width: 22px;
                  display: flex;
                  align-items: center;
                  &:hover{
                      color:$wp-danger-red;
                  }
                }*/
}

.wp-diary-selection-view .selected-slots-bar .selected-bar-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-right: 15px;
}

.wp-diary-selection-view .selected-slots-bar .delete-timeslot-selection {
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #66BB6A;
  color: #373D3F;
  position: absolute;
  right: 0;
  max-height: 25px;
  transition: 0.3s;
  cursor: pointer;
}

.wp-diary-selection-view .selected-slots-bar .delete-timeslot-selection i {
  color: #373D3F;
}

.wp-diary-selection-view .selected-slots-bar .delete-timeslot-selection i:hover {
  color: #DC2A2A;
}

.wp-diary-selection-view .opt-not-selectable {
  background-color: #f5f5f5;
  border: 0px;
  border-bottom: 1px solid #e0e0e0 !important;
  /*&:hover{
            cursor:not-allowed;
            border-bottom: 1px solid #e0e0e0 !important;
        }*/
}

.wp-diary-selection-view .opt-selected {
  background-color: #66BB6A;
}

.wp-diary-weekly-view .wp-table {
  display: block;
  width: 100%;
}

.wp-diary-weekly-view .wp-table-row {
  display: flex;
  position: relative;
  min-height: 50px;
  width: 100%;
}

.wp-diary-weekly-view .wp-table-row .resource-column-cell {
  width: unset !important;
  max-width: 250px;
}

.wp-diary-weekly-view .wp-table-row .resource-column-cell i {
  display: none;
}

.wp-diary-weekly-view .wp-table-row .resource-column-cell.venue-cell {
  font-weight: bold;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.wp-diary-weekly-view .wp-table-row .resource-column-cell.venue-cell:hover {
  cursor: pointer;
  background-color: #e0e0e0;
}

.wp-diary-weekly-view .wp-table-row .resource-column-cell.venue-cell i {
  display: block !important;
  color: #2574A9;
}

.wp-diary-weekly-view .wp-table-row .resource-column-cell.venue-cell .resource-label {
  padding-left: 0px !important;
}

.wp-diary-weekly-view .wp-table-row .resource-column-cell.venue-cell .price-label {
  padding-left: 0px !important;
}

.wp-diary-weekly-view .wp-table-row .week-day-cell {
  flex-grow: 1;
  min-width: 135px;
}

.wp-diary-weekly-view .wp-table-row .week-timeslot-cell {
  flex-grow: 1;
  min-width: 135px !important;
}

.wp-diary-weekly-view .wp-table-row:first-child {
  position: sticky;
  top: 0px;
  z-index: 998;
  background: #f5f5f5;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
}

.wp-diary-weekly-view .wp-table-cell, .wp-diary-weekly-view .wp-table-head {
  border: 1px solid #e0e0e0;
  display: table-cell;
  padding: 3px 10px;
}

.wp-diary-weekly-view .wp-table-foot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}

.wp-diary-weekly-view .wp-table-body {
  display: block;
  height: 300px;
  max-height: 300px;
  border-bottom: 1px solid #e0e0e0;
  overflow-y: scroll;
  position: relative;
}

.wp-diary-weekly-view .resource-column-cell {
  min-height: 50px;
  width: 250px;
  min-width: 250px;
  padding: 10px;
  position: sticky;
  left: 0px;
  background-color: #fff;
  z-index: 99;
  display: flex;
  align-items: center;
}

.wp-diary-weekly-view .resource-column-cell .price-label {
  font-size: 12px;
  padding-left: 10px;
}

.wp-diary-weekly-view .resource-column-cell .resource-label {
  white-space: normal;
  padding-left: 10px;
}

.wp-diary-weekly-view .timeslot-cell {
  width: 25px;
  min-width: 25px;
  padding: 0px;
  background-color: #fff;
}

.wp-diary-weekly-view .hour-cell {
  width: 100px;
  min-width: 100px;
  padding: 0px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-diary-weekly-view .week-day-cell {
  width: 150px;
  min-width: 150px;
  padding: 0px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.wp-diary-weekly-view .week-timeslot-cell {
  width: 150px;
  min-width: 150px;
  padding: 0px;
  background-color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

.wp-diary-weekly-view .week-timeslot-cell .wp-txt-body1--xsmall {
  text-align: center;
}

.wp-diary-weekly-view .week-timeslot-cell:hover {
  /*background-color:$wp-color-primary;*/
}

.wp-diary-weekly-view .week-timeslot-cell:hover .week-cell-item {
  background-color: #2574A9;
}

.wp-diary-weekly-view .week-timeslot-cell:hover .week-cell-item .wp-txt-body1--xsmall {
  color: #fff;
}

.wp-diary-weekly-view .selected-cell {
  background-color: #66BB6A;
  height: 100%;
  min-width: 25px;
  width: 25px;
}

.wp-diary-weekly-view .selected-slots-bar {
  height: 25px;
  position: absolute;
  top: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
  display: flex;
  align-items: center;
  /*&::after {
                  font-family: 'Material Icons';
                  content: "delete";
                  -webkit-font-feature-settings: 'liga';
                  position: absolute;
                  right: 0;
                  top:0px;
                  color: $wp-txt-primary;
                  height: 22px;
                  cursor: pointer;
                  width: 22px;
                  display: flex;
                  align-items: center;
                  &:hover{
                      color:$wp-danger-red;
                  }
                }*/
}

.wp-diary-weekly-view .selected-slots-bar .delete-timeslot-selection {
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #66BB6A;
  position: absolute;
  right: 0;
  max-height: 25px;
  transition: 0.3s;
  cursor: pointer;
}

.wp-diary-weekly-view .selected-slots-bar .delete-timeslot-selection i {
  color: #373D3F;
}

.wp-diary-weekly-view .selected-slots-bar .delete-timeslot-selection i:hover {
  color: #DC2A2A;
}

.wp-diary-weekly-view .opt-not-selectable {
  background-color: #f5f5f5;
  border: 0px;
  border-bottom: 1px solid #e0e0e0;
}

.wp-diary-weekly-view .opt-selected {
  background-color: #66BB6A;
}

.wp-diary-weekly-view .week-cell-inner-container {
  padding: 3px;
}

.wp-diary-weekly-view .week-cell-inner-container > .wp_flex__item:nth-last-child(1) {
  margin-bottom: 0px !important;
}

.wp-diary-weekly-view .week-cell-inner-container .week-cell-item--booked {
  background-color: #DC2A2A;
  margin-bottom: 3px;
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-diary-weekly-view .week-cell-inner-container .week-cell-item--booked .wp-txt-body1--xsmall {
  color: #ffffff !important;
}

.wp-diary-weekly-view .week-cell-inner-container .week-cell-item--selected {
  background-color: #66BB6A;
  margin-bottom: 3px;
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-diary-weekly-view .week-cell-inner-container .week-cell-item--selected .wp-txt-body1--xsmall {
  color: #000000 !important;
}

.wp-diary-weekly-view .week-cell-inner-container .week-cell-item--special {
  background-color: #bfbfbf;
  margin-bottom: 3px;
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-diary-weekly-view .week-cell-inner-container .week-cell-item--special .wp-txt-body1--xsmall {
  color: #000000 !important;
}

.wp-diary-weekly-view .wp-week-switcher {
  justify-content: center;
}

.wp-diary-weekly-view .wp-week-switcher .mat-form-field {
  position: absolute;
  visibility: hidden;
}

.wp-diary-weekly-view .wp-week-switcher .mat-btn-icon {
  border: 1px solid #2574A9;
  border: 1px solid #2574A9;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-diary-weekly-view .wp-week-switcher .mat-btn-icon:hover {
  transition: 0.3s;
}

.wp-diary-weekly-view .wp-week-switcher .mat-btn-icon:hover .material-icons {
  color: #2574A9 !important;
}

.wp-diary-weekly-view .legend-container {
  margin-top: 15px;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.wp-diary-weekly-view .legend-container .selected-date-legend {
  background-color: #2E7D32;
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.wp-diary-weekly-view .legend-container .unavailable-date-legend {
  background-color: #DC2A2A;
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.wp-diary-weekly-view .legend-container .special-date-legend {
  background-color: #BFBFBF;
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.wp-location-resource-search .search-panel {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
}

.wp-location-resource-search .search-panel .mat-form-field {
  width: 100%;
}

.wp-location-resource-search .search-panel .mat-form-field .mat-form-field-infix {
  line-height: 20px;
}

.wp-location-resource-search .search-panel .mat-form-field .mat-select-panel {
  min-width: 112px !important;
}

.wp-location-resource-search .search-panel .cdk-overlay-container .cdk-overlay-pane .mat-select-panel.filter-by-venue-panel {
  min-width: 112px !important;
}

.wp-location-resource-search .search-results-count {
  margin-bottom: 15px;
}

.wp-location-resource-search .facility-selection-row {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 5px;
}

.wp-location-resource-search .facility-selection-row:nth-last-child(1) {
  margin-bottom: 0px;
}

.wp-location-resource-search .facility-selection-row img {
  width: 20px;
  margin: 0 5px;
}

.wp-location-resource-search .facility-selection-row a {
  white-space: normal;
}

.wp-location-resource-search .range-selector-container {
  position: relative;
  top: -8px;
}

.wp-location-resource-search .range-selector-container .placeholder-label {
  font-size: 10px;
}

.search-pagi .mat-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select {
  width: 56px !important;
}

.room-drop {
  padding: 5px 5px 0 5px;
  width: 15%;
}

.room-drop .room-drop-inner {
  width: 15%;
}

.cdk-overlay-pane {
  overflow-x: hidden;
}

.top-padding {
  padding-top: 5px;
}

.facility-selection-inner-row {
  display: flex;
  justify-content: space-between;
}

.max-capacity {
  white-space: nowrap;
}

.wp-add-teams {
  background-color: #ffffff;
}

.wp-add-teams .mat-form-field {
  width: 100%;
}

.wp-add-teams .add-teams-header {
  padding: 15px 75px 15px 15px;
}

.wp-add-teams .add-teams-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.wp-add-teams .add-teams-row .left-col {
  padding: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  width: calc(100% - 60px);
  border-radius: 10px;
  background-color: #ffffff;
}

.wp-add-teams .add-more-button {
  margin-top: 20px;
  width: calc(100% - 60px);
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.wp-add-teams .add-more-button i {
  margin-right: 10px;
  color: #2574A9;
}

.wp-questionnaire .filter-panel {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
}

.wp-questionnaire .filter-panel .mat-form-field {
  width: 100%;
}

.wp-questionnaire .filter-panel .mat-form-field .mat-form-field-infix {
  line-height: 30px;
}

.wp-questionnaire .questionnaire-header {
  margin-bottom: 20px;
}

.wp-questionnaire .questionnaire-header .mat-slide-toggle {
  height: 16px;
}

.wp-questionnaire .questionnaire-header .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #2574a96b;
}

.wp-questionnaire .questionnaire-header .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #2574A9;
}

.wp-questionnaire .questionnaire-header .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #2574A9;
}

.wp-questionnaire .questionnaire-questions-wrapper {
  background-color: #f5f5f5;
  padding: 15px;
}

.wp-questionnaire .questionnaire-questions-wrapper .question-title {
  margin: 10px 0px;
}

.wp-questionnaire .questionnaire-questions-wrapper .mat-radio-button {
  margin-right: 15px;
}

.wp-questionnaire .questionnaire-questions-wrapper .mat-form-field {
  width: 100%;
}

.wp-questionnaire .questionnaire-questions-wrapper .mat-expansion-panel.mat-expanded {
  margin-bottom: 10px !important;
}

.rb-web-none {
  display: none !important;
}

.wp-rb-main-layout {
  display: none;
  display: block;
  background-color: #f5f5f5;
}

@media (min-width: 300px) {
  .wp-rb-main-layout {
    display: none;
  }
}

@media (min-width: 1024px) {
  .wp-rb-main-layout {
    display: block;
  }
}

.wp-rb-main-layout .wp-main-header {
  padding: 15px;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  margin-bottom: 30px;
}

.wp-rb-main-layout .wp-main-header i {
  color: #7E57C2;
}

.wp-rb-main-layout .wp-main-header .profile-avatar {
  height: 35px;
  width: 35px;
  margin-right: 10px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-rb-main-layout .wp-main-header .profile-avatar img {
  height: 35px;
}

.wp-rb-main-layout .step-header {
  margin-bottom: 20px;
}

.wp-rb-main-layout .step-header .export-icon {
  color: #7E57C2;
}

.wp-rb-main-layout .step-header .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
  padding: 5px 0px 0px 0px;
}

.wp-rb-main-layout .wp-stepper .mat-horizontal-content-container {
  overflow: visible;
}

.wp-rb-main-layout .wp-add-teams {
  margin-top: 30px;
}

.wp-rb-main-layout .stepper-nav-button-row {
  margin-top: 50px;
}

.wp-rb-main-layout .calendar-toggle, .wp-rb-main-layout .date-selection-toggle {
  margin-top: 15px;
  margin-top: 0px;
  border: unset;
}

@media (min-width: 300px) {
  .wp-rb-main-layout .calendar-toggle, .wp-rb-main-layout .date-selection-toggle {
    margin-top: 15px;
  }
}

@media (min-width: 1024px) {
  .wp-rb-main-layout .calendar-toggle, .wp-rb-main-layout .date-selection-toggle {
    margin-top: 0px;
  }
}

.wp-rb-main-layout .calendar-toggle .mat-button-toggle, .wp-rb-main-layout .date-selection-toggle .mat-button-toggle {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #2574A9;
  color: #2574A9 !important;
  text-align: center !important;
  font-size: 14px !important;
  transition: 0.3s !important;
  height: 30px;
  padding: 0 10px;
  width: auto;
}

.wp-rb-main-layout .calendar-toggle .mat-button-toggle:nth-child(1), .wp-rb-main-layout .date-selection-toggle .mat-button-toggle:nth-child(1) {
  border-radius: 10px 0px 0 10px !important;
}

.wp-rb-main-layout .calendar-toggle .mat-button-toggle:nth-last-child(1), .wp-rb-main-layout .date-selection-toggle .mat-button-toggle:nth-last-child(1) {
  border-radius: 0 10px 10px 0px !important;
}

.wp-rb-main-layout .calendar-toggle .mat-button-toggle .mat-button-toggle-label-content, .wp-rb-main-layout .date-selection-toggle .mat-button-toggle .mat-button-toggle-label-content {
  line-height: 28px;
}

.wp-rb-main-layout .calendar-toggle .mat-button-toggle-checked, .wp-rb-main-layout .date-selection-toggle .mat-button-toggle-checked {
  background-color: #2574A9;
  color: #ffffff !important;
}

.wp-rb-main-layout .btn-location-resource-search {
  height: 40px !important;
}

.wp-rb-main-layout .message--bubble-notification {
  position: fixed;
  right: 60px;
  z-index: 9999;
  top: 130px;
  min-width: 320px;
}

.wp-rb-main-layout .booking-application-name-wrapper {
  padding: 20px 10px;
  background-color: #ffffff;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  width: 90%;
}

.wp-rb-main-layout .booking-application-name-wrapper .mat-form-field {
  width: 100%;
}

.wp-rb-main-layout .submit-panel-container {
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 15px;
}

.wp-rb-main-layout .terms-checkbox .mat-checkbox-inner-container {
  margin: 0;
  margin-right: 10px;
}

.wp-rb-main-layout .submit-documents-panel, .wp-rb-main-layout .submit-additional-notes-panel {
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  height: 100%;
}

.wp-rb-main-layout .submit-documents-panel .mat-form-field, .wp-rb-main-layout .submit-additional-notes-panel .mat-form-field {
  width: 100%;
}

.wp-rb-main-layout .wp-booking-details-modal .wp-modal-body {
  max-height: 450px;
}

.wp-rb-home {
  display: none;
  display: block;
  padding-bottom: 30px;
}

@media (min-width: 300px) {
  .wp-rb-home {
    display: none;
  }
}

@media (min-width: 1024px) {
  .wp-rb-home {
    display: block;
  }
}

.wp-rb-home .wp-main-header {
  padding: 15px;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  margin-bottom: 30px;
}

.wp-rb-home .wp-main-header i {
  color: #7E57C2;
}

.wp-rb-home .wp-main-header .profile-avatar {
  height: 35px;
  width: 35px;
  margin-right: 10px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-rb-home .wp-main-header .profile-avatar img {
  height: 35px;
}

.wp-rb-home .home-inner-container {
  background-color: #ffffff;
  padding: 0px;
  border-radius: 5px;
}

.wp-rb-home .home-inner-container .booking-type-container {
  flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 300px) {
  .wp-rb-home .home-inner-container .booking-type-container {
    flex-wrap: wrap;
  }
}

@media (min-width: 1200px) {
  .wp-rb-home .home-inner-container .booking-type-container {
    flex-wrap: wrap;
  }
}

.wp-rb-home .home-inner-container .booking-type-container > .wp_flex__item {
  padding-right: 15px;
  max-width: 50%;
  max-width: 25%;
}

@media (min-width: 300px) {
  .wp-rb-home .home-inner-container .booking-type-container > .wp_flex__item {
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .wp-rb-home .home-inner-container .booking-type-container > .wp_flex__item {
    max-width: 25%;
  }
}

.wp-rb-home .home-inner-container .booking-type-container > .wp_flex__item:nth-child(1) .btn-booking-type:hover {
  border-bottom: 3px solid #2574A9;
}

.wp-rb-home .home-inner-container .booking-type-container > .wp_flex__item:nth-child(2) .btn-booking-type:hover {
  border-bottom: 3px solid #9B59B6;
}

.wp-rb-home .home-inner-container .booking-type-container > .wp_flex__item:nth-child(3) .btn-booking-type:hover {
  border-bottom: 3px solid #AA5D00;
}

.wp-rb-home .home-inner-container .btn-booking-type {
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  padding: 20px;
  transition: 0.1s !important;
  height: 200px;
  width: 100%;
  margin-bottom: 15px;
  margin-bottom: 10px;
  /*&.btn-regular-booking{
                i{
                    color:$wp-color-primary;
                }
                &:hover{
                    border-bottom:3px solid $wp-color-primary;
                }
            }
            &.btn-seasonal-booking{
                i{
                    color:$wp-color-secondary;
                }
                &:hover{
                    border-bottom:3px solid $wp-color-secondary;
                }
            }
            &.btn-personal-training{
                i{
                    color:$wp-color-tertiary;
                }
                &:hover{
                    border-bottom:3px solid $wp-color-tertiary;
                }
            }*/
}

@media (min-width: 300px) {
  .wp-rb-home .home-inner-container .btn-booking-type {
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .wp-rb-home .home-inner-container .btn-booking-type {
    margin-bottom: 10px;
  }
}

.wp-rb-home .home-inner-container .btn-booking-type i {
  font-size: 35px;
  margin-bottom: 10px;
}

.wp-rb-home .home-inner-container .btn-booking-type img {
  margin-bottom: 15px;
}

.wp-rb-home .home-inner-container .btn-booking-type .btn-description {
  max-width: 350px;
  min-width: 210px;
  white-space: normal;
  font-weight: 400;
  line-height: 18px;
}

.device-support-message {
  height: 50vh;
  align-items: center;
  padding: 25px;
  display: flex;
  display: none;
}

@media (min-width: 300px) {
  .device-support-message {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .device-support-message {
    display: none;
  }
}

.wp-rb-home .wp-main-header .wp-txt--link {
  color: #000000 !important;
}

.wp-rb-home .wp-main-header .wp-txt--link span {
  min-width: 200px;
  display: flex;
  align-items: center;
}

.wp-rb-home .wp-main-header .wp-txt--link span:before {
  content: 'account_balance';
  font-family: "Material Icons";
  background-color: #c9da43;
  color: #000 !important;
  transition: 0.3s;
  margin-right: 5px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-rb-home .wp-main-header .wp-txt--link span:hover {
  background-color: #e0e0e0;
  border-radius: 12px;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
}

.wp-rb-home .wp-main-header .wp-txt--link span:hover:before {
  background-color: #000;
  color: #c9da43;
}

.extra-icon {
  background-color: #00507c !important;
}

.extra-body {
  padding: 25px 60px !important;
  max-height: 70vh;
  overflow-y: scroll;
}

.extra-body .extra-body-header-wrapper {
  display: flex;
  border-bottom: 2px solid lightgray;
  padding-bottom: 15px;
}

.extra-body .extra-body-header-wrapper .extra-body-image .extra-image {
  height: 10vh;
}

.extra-body .extra-body-header-wrapper .extra-header-title-wrapper {
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.extra-body .extra-body-header-wrapper .extra-header-title-wrapper .extra-select-item-name {
  font-weight: 600;
}

.extra-body .extra-body-header-wrapper .extra-header-title-wrapper .extra-select-item-quantity {
  padding: 0 10px;
  font-weight: 500;
}

.extra-body .extra-body-header-wrapper .extra-header-price {
  display: flex;
  align-items: center;
}

.extra-body .extra-content-wrapper {
  display: flex;
  padding: 15px 0;
}

.extra-body .extra-content-wrapper .extra-content-title {
  font-weight: 600;
  padding-right: 15px;
}

.extra-body .extra-content-wrapper .extra-contetnt-check .mat-checkbox-inner-container {
  margin: 0 !important;
  margin-right: 10px !important;
}

.extra-body .extra-content-table .table-check .mat-checkbox-inner-container {
  margin: 0;
  margin-right: 10px;
}

.extra-body .extra-content-table .table-date-wrapper {
  display: flex;
}

.extra-body .extra-content-table .table-date-wrapper .table-start-date {
  font-weight: 600;
  color: #00507c;
}

.extra-body .extra-content-table .table-date-wrapper .table-start-time {
  margin-left: 15px;
  color: #00507c;
}

.extra-body .extra-content-table .table-image-wrapper {
  display: flex;
}

.extra-body .extra-content-table .table-image-wrapper .table-quantity {
  margin-left: 12px;
}

.extra-allocation-footer {
  padding: 0 75px !important;
}

.extra-allocation-footer .extra-footer-wrap {
  display: flex !important;
  justify-content: flex-end !important;
}

.extra-allocation-footer .extra-footer-wrap .extra-footer-btn {
  width: 180px !important;
}

.section-title-second {
  font-size: 1.375rem;
  letter-spacing: 0.05rem;
  line-height: 2.0625rem;
  word-spacing: 0.22rem;
  font-weight: 600;
  color: #00507c;
  margin-left: 10px;
}

.extra-deletion-body {
  padding: 25px 60px !important;
  max-height: 70vh;
  overflow-y: scroll;
}

.extra-deletion-body .extra-deletion-body-header-wrapper {
  display: flex;
  border-bottom: 2px solid lightgray;
  padding-bottom: 15px;
}

.extra-deletion-body .extra-deletion-body-header-wrapper .extra-deletion-body-image .extra-deletion-image {
  height: 10vh;
}

.extra-deletion-body .extra-deletion-body-header-wrapper .extra-deletion-header-title-wrapper {
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.extra-deletion-body .extra-deletion-body-header-wrapper .extra-deletion-header-title-wrapper .extra-deletion-select-item-name {
  font-weight: 600;
}

.extra-deletion-body .extra-deletion-body-header-wrapper .extra-deletion-header-title-wrapper .extra-deletion-select-item-quantity {
  padding: 0 10px;
  font-weight: 500;
}

.extra-deletion-body .extra-deletion-body-header-wrapper .extra-deletion-header-price {
  display: flex;
  align-items: center;
}

.extra-deletion-body .extra-deletion-content-radio-wrapper {
  padding: 20px 0px;
}

.extra-deletion-body .extra-deletion-content-radio-wrapper .deletion-radio-btn .mat-radio-container {
  top: 0 !important;
}

.deletion-allocation-footer {
  padding: 0 75px !important;
}

.deletion-allocation-footer .deletion-footer-wrap {
  display: flex !important;
  justify-content: flex-end !important;
}

.deletion-allocation-footer .deletion-footer-wrap .deletion-footer-btn {
  width: 180px !important;
}

.header__user .header-nav-venue:before, .header__user .header-nav-rb:before {
  background-color: #c9da43 !important;
  color: #000 !important;
}

.header__user .header-nav-venue:hover:before, .header__user .header-nav-rb:hover:before {
  background-color: #000 !important;
  color: #c9da43 !important;
}

.header__user .waste-header-flex {
  display: flex;
  width: auto;
}

@media screen and (max-width: 577px) {
  .sub-header-user {
    margin-bottom: 0px !important;
    z-index: unset !important;
  }
}

.header__alter-menu .sub_left .wm-search_customer, .header__alter-menu .sub_left .wm-new_customer, .header__alter-menu .sub_left .wm-selected-customer-profile, .header__alter-menu .sub_left .wm-go-home, .wm-login-user-profile-icon-wrapper .wm-signIn-user-icon, .wm-login-user-profile-icon-wrapper .wm-dashboard-icon {
  height: 70px;
  min-width: 50px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  text-decoration: unset !important;
  color: #2574A9 !important;
}

@media screen and (max-width: 768px) {
  .header__alter-menu .sub_left .wm-search_customer, .header__alter-menu .sub_left .wm-new_customer, .header__alter-menu .sub_left .wm-selected-customer-profile, .header__alter-menu .sub_left .wm-go-home, .wm-login-user-profile-icon-wrapper .wm-signIn-user-icon, .wm-login-user-profile-icon-wrapper .wm-dashboard-icon {
    padding-bottom: 5px;
  }
}

.header__alter-menu .sub_left .wm-search_customer:before, .header__alter-menu .sub_left .wm-new_customer:before, .header__alter-menu .sub_left .wm-go-home:before, .wm-login-user-profile-icon-wrapper .wm-signIn-user-icon:before, .wm-login-user-profile-icon-wrapper .wm-dashboard-icon:before {
  font-family: "Material Icons";
  transition: 0.3s;
  margin-right: 5px;
  border: 1px solid;
  border-radius: 50%;
  padding: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger !important;
}

.header__alter-menu .sub_left .wm-search_customer:hover:before, .header__alter-menu .sub_left .wm-new_customer:hover:before, .header__alter-menu .sub_left .wm-selected-customer-profile:hover:before, .header__alter-menu .sub_left .wm-go-home:hover:before, .wm-login-user-profile-icon-wrapper .wm-signIn-user-icon:hover:before, .wm-login-user-profile-icon-wrapper .wm-dashboard-icon:hover:before {
  background-color: #787272;
  color: #fff;
}

.header__alter-menu .sub_left .wm-search_customer {
  margin-left: 20px;
}

.header__alter-menu .sub_left .wm-search_customer:focus {
  outline: 2px solid black;
}

.header__alter-menu .sub_left .wm-search_customer:before {
  content: 'group';
}

.header__alter-menu .sub_left .wm-new_customer:before {
  content: 'person_add';
}

.header__alter-menu .sub_left .wm-go-home:before {
  content: 'home';
  background-color: #c9da43 !important;
  color: #000 !important;
}

@media only screen and (max-width: 768px) {
  .header__alter-menu .sub_left .wm-go-home:after {
    content: '';
    padding-left: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .header__alter-menu .sub_left {
    border-bottom: 1px solid #b7b7b7;
    min-height: 70px;
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .header__alter-menu .sub {
    min-height: 70px;
  }
}

.wm-login-user-profile-icon-wrapper {
  position: relative;
}

.wm-login-user-profile-icon-wrapper .wm-signIn-user-icon {
  cursor: pointer;
}

.wm-login-user-profile-icon-wrapper .wm-signIn-user-icon:before {
  content: 'person';
}

.wm-login-user-profile-icon-wrapper .wm-dashboard-icon {
  cursor: pointer;
}

.wm-login-user-profile-icon-wrapper .wm-dashboard-icon:before {
  content: 'apps';
}

.wm-login-user-profile-icon-wrapper .wm-profile-img {
  padding-right: 15px;
}

.wm-login-user-profile-icon-wrapper .wm-profile-avatar {
  height: 35px;
  width: 35px;
  margin-right: 20px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wm-login-user-profile-icon-wrapper .wm-profile-avatar img {
  height: 35px;
  width: 35px;
}

@media (max-width: 1024px) {
  .wm-login-user-profile-icon-wrapper .wm-profile-avatar {
    margin-top: 0px !important;
  }
}

.wm-login-user-profile-icon-wrapper .wm-selected-cus-img {
  margin-right: 7px !important;
}

.wm-login-user-profile-icon-wrapper .wm-relative-img {
  position: relative;
  left: 7px;
}

.wm-home-cart {
  position: unset !important;
  position: unset !important;
  flex-grow: inherit !important;
  flex-grow: inherit !important;
}

@media (min-width: 300px) {
  .wm-home-cart {
    position: unset !important;
  }
}

@media (min-width: 768px) {
  .wm-home-cart {
    position: unset !important;
  }
}

@media (min-width: 1024px) {
  .wm-home-cart {
    flex-grow: inherit !important;
  }
}

@media (min-width: 1200px) {
  .wm-home-cart {
    flex-grow: inherit !important;
  }
}

.navbar-light .sub .mmc_flex__item--inherit.align--center.wm-name-left {
  padding-left: 0 !important;
  border-right: 1px solid #b7b7b7 !important;
  border-left: none !important;
  padding-left: 0 !important;
  border-right: 1px solid #b7b7b7 !important;
  border-left: none !important;
  padding-left: 0;
  border-right: none !important;
  padding-left: 0;
  border-right: none !important;
}

@media (min-width: 300px) {
  .navbar-light .sub .mmc_flex__item--inherit.align--center.wm-name-left {
    padding-left: 0 !important;
    border-right: 1px solid #b7b7b7 !important;
    border-left: none !important;
  }
}

@media (min-width: 768px) {
  .navbar-light .sub .mmc_flex__item--inherit.align--center.wm-name-left {
    padding-left: 0 !important;
    border-right: 1px solid #b7b7b7 !important;
    border-left: none !important;
  }
}

@media (min-width: 1024px) {
  .navbar-light .sub .mmc_flex__item--inherit.align--center.wm-name-left {
    padding-left: 0;
    border-right: none !important;
  }
}

@media (min-width: 1200px) {
  .navbar-light .sub .mmc_flex__item--inherit.align--center.wm-name-left {
    padding-left: 0;
    border-right: none !important;
  }
}

.wm-header-item {
  display: none;
}

.wm-top-name {
  display: flex;
  justify-content: flex-start !important;
  display: flex;
  justify-content: flex-start !important;
  justify-content: flex-end !important;
  justify-content: flex-end !important;
}

@media (min-width: 300px) {
  .wm-top-name {
    display: flex;
    justify-content: flex-start !important;
  }
}

@media (min-width: 768px) {
  .wm-top-name {
    display: flex;
    justify-content: flex-start !important;
  }
}

@media (min-width: 1024px) {
  .wm-top-name {
    justify-content: flex-end !important;
  }
}

@media (min-width: 1200px) {
  .wm-top-name {
    justify-content: flex-end !important;
  }
}

.wm-sub-d-flex {
  width: 100%;
}

.wm-header-upper {
  padding-left: 25px;
  padding-left: 25px;
  position: unset;
  position: unset;
}

@media (min-width: 300px) {
  .wm-header-upper {
    padding-left: 25px;
  }
}

@media (min-width: 768px) {
  .wm-header-upper {
    padding-left: 25px;
  }
}

@media (min-width: 1024px) {
  .wm-header-upper {
    position: unset;
  }
}

@media (min-width: 1200px) {
  .wm-header-upper {
    position: unset;
  }
}

.wm-wp-mr {
  width: unset !important;
  width: unset !important;
  width: unset;
  min-width: fit-content;
  width: unset;
  min-width: fit-content;
}

@media (min-width: 300px) {
  .wm-wp-mr {
    width: unset !important;
  }
}

@media (min-width: 768px) {
  .wm-wp-mr {
    width: unset !important;
  }
}

@media (min-width: 1024px) {
  .wm-wp-mr {
    width: unset;
    min-width: fit-content;
  }
}

@media (min-width: 1200px) {
  .wm-wp-mr {
    width: unset;
    min-width: fit-content;
  }
}

.wm-header-search {
  margin-left: unset !important;
  margin-left: unset !important;
  margin-left: 20px !important;
  margin-left: 20px !important;
}

@media (min-width: 300px) {
  .wm-header-search {
    margin-left: unset !important;
  }
}

@media (min-width: 768px) {
  .wm-header-search {
    margin-left: unset !important;
  }
}

@media (min-width: 1024px) {
  .wm-header-search {
    margin-left: 20px !important;
  }
}

@media (min-width: 1200px) {
  .wm-header-search {
    margin-left: 20px !important;
  }
}

.wm-header-customer {
  padding-left: 15px;
  padding-right: 0 !important;
  padding-left: 15px;
  padding-right: 0 !important;
  padding-left: 15px;
  padding-right: 0 !important;
  padding-left: 15px;
  padding-right: 0 !important;
}

@media (min-width: 300px) {
  .wm-header-customer {
    padding-left: 15px;
    padding-right: 0 !important;
  }
}

@media (min-width: 768px) {
  .wm-header-customer {
    padding-left: 15px;
    padding-right: 0 !important;
  }
}

@media (min-width: 1024px) {
  .wm-header-customer {
    padding-left: 15px;
    padding-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .wm-header-customer {
    padding-left: 15px;
    padding-right: 0 !important;
  }
}

.wm-go-back {
  position: absolute;
  top: -160px;
  z-index: 1000;
  left: 215px;
  position: absolute;
  top: -160px;
  z-index: 1000;
  left: 215px;
  position: unset;
  position: unset;
}

@media (min-width: 300px) {
  .wm-go-back {
    position: absolute;
    top: -160px;
    z-index: 1000;
    left: 215px;
  }
}

@media (min-width: 768px) {
  .wm-go-back {
    position: absolute;
    top: -160px;
    z-index: 1000;
    left: 215px;
  }
}

@media (min-width: 1024px) {
  .wm-go-back {
    position: unset;
  }
}

@media (min-width: 1200px) {
  .wm-go-back {
    position: unset;
  }
}

.wm-customer {
  min-width: fit-content;
}

.wm-select-profile {
  height: 63px !important;
  border: 1px solid lightgrey;
  background-color: #f2f2f2;
  border-radius: 3px;
  position: relative;
  top: -4px;
  margin: 0px 10px;
}

.wm-header-close-btn {
  font-size: 16px !important;
}

@media screen and (max-width: 768px) {
  .past-booking .navbar-collapse .header__alter-menu .sub_left span {
    height: auto !important;
  }
  .header__user .waste-header-flex {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: space-between;
  }
  .header__user .waste-header-flex .wp-mr-20 {
    padding-top: unset !important;
  }
}

@media screen and (max-width: 400px) {
  .wm-sign-in-btn {
    width: 88% !important;
    display: flex;
    justify-content: space-between;
  }
}

.wm-sign-in-btn button {
  display: flex;
  margin-right: 15px;
  width: 42px !important;
  min-width: unset;
}

@media screen and (max-width: 768px) {
  .wm-sign-in-btn button {
    margin-right: 0px;
    justify-content: end !important;
  }
}

@media only screen and (max-width: 768px) {
  .wm-sign-in-btn div:nth-child(1) {
    width: 80%;
  }
}

.wm-sign-in-btn button i {
  margin: 0px !important;
  padding: 0px 12px !important;
}

.mmc_enquiry .bottom-line .bottom-line--topic .line {
  position: relative;
  top: 10px;
}

span.nameEle {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 12ch !important;
}
