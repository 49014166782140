.extra-icon {
    background-color: #00507c !important;
}

.extra-body {
    padding: 25px 60px !important;
    max-height: 70vh;
    overflow-y: scroll;

    .extra-body-header-wrapper {
        display: flex;
        border-bottom: 2px solid lightgray;
        padding-bottom: 15px;
    
        .extra-body-image {
    
            .extra-image {
                height: 10vh;
            }
        }
    
        .extra-header-title-wrapper {
            display: flex;
            align-items: center;
            padding: 0 40px;            
    
            .extra-select-item-name {
                font-weight: 600;    
            }
    
            .extra-select-item-quantity {
                padding: 0 10px;  
                font-weight: 500;  
            }
    
        }
    
        .extra-header-price {
            display: flex;
            align-items: center;
    
        }
    }
    
    .extra-content-wrapper {
        display: flex;
        padding: 15px 0;
    
        .extra-content-title {
            font-weight: 600;
            padding-right: 15px;    
        }
    
        .extra-contetnt-check {

            .mat-checkbox-inner-container {
                margin: 0 !important;
                margin-right: 10px !important;
            }
    
        }
    }

    .extra-content-table {

        .table-check {

            .mat-checkbox-inner-container {
                margin: 0;
                margin-right: 10px;
            }

        }

        .table-date-wrapper {
            display: flex;

            .table-start-date {
                font-weight: 600;
                color: #00507c;

            }

            .table-start-time {
                margin-left: 15px;
                color: #00507c;

            }
        }

        .table-image-wrapper {
            display: flex;

            .table-image {

            }

            .table-quantity {
                margin-left: 12px;

            }
        }

        .table-price {

        }
    }

}

.extra-allocation-footer {
    padding: 0 75px !important;

    .extra-footer-wrap {
        display: flex !important;
        justify-content: flex-end !important;

        .extra-footer-btn {
            width: 180px !important;
        }
    }
}


