﻿.wp-timeslot-calendar{
    .timeslot-container{
        background-color:$wp-color-white;
    }
    .select-team-dropdown{
        padding:2px;
        text-align: center;
        min-width:400px;
        max-width: 450px;
            @include respond-to('x-small'){
                margin-top:10px;
            }
            @include respond-to('medium'){
                margin-top:0px;
            }      
        .mat-form-field{
                width:100%;
                // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                padding: 0px;
                border-radius: 5px;
                transition:0.3s;
                border-bottom: 0.1px solid #e8e8e8;
                .mat-select-value-text{
                    color: $wp-color-primary;
                    font-size: 18px;
                    font-weight: 400;
                }
                &:hover{
                    .mat-form-field-underline{
                        display:block;
                        height:3px;
                        transition: 0.3s;
                        background-color:$wp-color-primary !important;
                    }
                }
                .mat-form-field-flex{
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    .mat-form-field-infix{
                        padding: 0 10px;
                    }
                }
                .mat-form-field-underline{
                    height:0px;
                    background-color:$wp-color-primary !important;
                }
            }
    }
    .weekview-header-label{
        padding-right:65px;
            @include respond-to('x-small'){
                margin-bottom:10px;
            }
            @include respond-to('medium'){
                margin-bottom:0px;
            }   
    }
    .weekview-header{
        margin-bottom:0px;
    }
    .hours-header{
        .hour-cell{
            vertical-align:middle;
        }
    }
    .day-header{
        .day-cell{
            transition:0.3s;
            &:hover{
                cursor:pointer;
                background-color:$wp-color-primary;
                color:$wp-color-white;
            }
        }
        .selected-date{
            background-color:$wp-color-primary;
            color:$wp-color-white;
        }
    }
    .venue-container{
        background-color:$wp-background-grey;
        padding:10px !important;
    }
    .calendar-cell{
        width:20px;
        &.hour-cell{
            width:80px;
        }
    }
    .calendar-hour-column{
        padding:0px !important;
        .wp-txt--link{
            font-size:12px;
        }
    }
    .opt-selected{
        background-color:$wp-secondary-green;
        position:relative;
        /*&:nth-last-child(1){
            &::after {
              font-family: 'Material Icons';
              content: "delete";
              -webkit-font-feature-settings: 'liga';
            }
        }*/
    }
}