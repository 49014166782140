﻿.wp-perfect-center{
    display:flex;
    align-items:center;
    justify-content:center;
}


//Margin classes
.wp-mb-5{
    margin-bottom:5px;
}
.wp-mb-10{
    margin-bottom:10px;
}
.wp-mb-15{
    margin-bottom:15px;
}
.wp-mb-20{
    margin-bottom:20px !important;
}
.wp-mb-30{
    margin-bottom:30px !important;
}
.wp-mr-5{
    margin-right:5px;
}
.wp-mr-10{
    margin-right:10px !important;
}
.wp-mr-20{
    margin-right:20px;
}
.wp-mt-0{
    margin-top:0px !important;
}
.wp-mt-10{
    margin-top:10px !important;
}
.wp-mt-20{
    margin-top:20px;
}
.wp-mt-30{
    margin-top:30px;
}
.wp-ml-10{
    margin-left:10px;
}
//Padding classes
.wp-pr-10{
    padding-right:10px;
}
.wp-pr-15{
    padding-right:15px;
}
.wp-pr-30{
    padding-right:30px;
}

//Width classes
.w-5{
    width:5% !important;
}
.w-10{
    width:10% !important;
}
.w-15{
    width:15% !important;
}
.w-20{
    width:20% !important;
}